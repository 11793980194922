import { useDispatch, useSelector } from 'react-redux'
import api_endpoints from '@services/api_endpoints'
import { getRequest } from '@services/axios'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { setTeamNumbers, setVendorList } from '@store/Slices/dashboard'
import { getRequestIdentifier, sortVendorsAlphabatically } from '@helpers/index'
import { useTranslation } from 'react-i18next'

export default function useDashboardEnhancer() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { personData } = useSelector((store: any) => store.personData)
  const { vendorList, vendorListOriginal, selectedVendor } = useSelector(
    (store: any) => store.dashboard
  )
  const { frequentSearchVendors, loginInfo } = useSelector(
    (store: any) => store.persistedData
  )

  useEffect(() => {
    // Check if personData is available, otherwise navigate to home
    if (!personData?.personId) {
      navigate('/')
    } else if (selectedVendor?.ID) {
      navigate('/pallet-scan')
    } else {
      // Fetch vendor list
      getVendorList()
      getTeamNumber()
    }
  }, [])

  // Function to get vendor list from the API
  const getVendorList = async () => {
    // Show loader
    if (!vendorListOriginal.length) {
      // not showing loader if vendoer list exist but refreshing in background
      dispatch(setLoader(true))
    }

    // Make API request to fetch vendor list
    const response: any = await getRequest(api_endpoints.getVendorList, {
      headers: {
        transaction_type: getRequestIdentifier(
          'VendorList',
          personData?.personId,
          loginInfo?.selectedWarehouse,
          ''
        ),
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
      },
    })

    if (response?.success) {
      // Set vendor list in the Redux store
      const sortedVendors = sortVendorsAlphabatically(
        response.data?.vendor_list
      )
      dispatch(
        setVendorList({
          sorted: sortedVendors,
          original: response.data?.vendor_list,
        })
      )
      // Hide loader
      dispatch(setLoader(false))
    } else {
      dispatch(setLoader(false))
      dispatch(
        setNotification({
          notification: t('Timeout reached, please retry after sometime'),
          variant: 'error',
        })
      )
    }
  }

  const getTeamNumber = async () => {
    const response: any = await getRequest(
      api_endpoints.getTeamNumber(loginInfo?.selectedWarehouse),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'Team Number',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            ''
          ),
          Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
        },
      }
    )
    if (response?.success) {
      dispatch(
        setTeamNumbers(
          response.data?.team_number?.map((item: any) => item?.TEAMNO)
        )
      )
    } else {
      dispatch(
        setNotification({
          notification: t('Timeout reached, please retry after sometime'),
          variant: 'error',
        })
      )
    }
  }

  // Return the necessary values
  return {
    vendorList,
    frequentSearchVendors,
    vendorListOriginal,
  }
}
