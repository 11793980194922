import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import { persistor } from './store'
import Login from './pages/Login'
import { useTranslation } from 'react-i18next'
import useIsOnline from './helpers/useIsOnline'
import 'rsuite/dist/rsuite-no-reset.min.css'
import { PersistGate } from 'redux-persist/integration/react'
import PageNotFound from './pages/PageNotFound'
import Pricing from './pages/Pricing'
import DuplicateTabModal from './components/DuplicateTab'
import { useEffect, useState } from 'react'
import CustomNotification from '@components/Notification'
import { PrintZPL } from '@components/printZpl'
import NoInternet from './components/NoInternet'

function App() {
  const { isOnline } = useIsOnline()
  const { t } = useTranslation()
  const [duplicateTabs, setDuplicateTabs] = useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const checkForDuplicateTabs = (e: any) => {
    if (e.key === 'openpages') {
      // Listen if anybody else is opening the same page!
      localStorage.setItem('page_available', `${Date.now()}`)
    }
    if (e.key === 'page_available') {
      setDuplicateTabs(true)
    }
  }

  useEffect(() => {
    localStorage.setItem('openpages', `${Date.now()}`)
    window.addEventListener('storage', checkForDuplicateTabs, false)
  }, [])

  return (
    <>
      <DuplicateTabModal {...{ duplicateTabs }} />
      <CustomNotification />
      <PrintZPL />
      {isOnline ? (
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Login t={t} />} />
              <Route
                path="/dashboard"
                element={
                  <Dashboard
                    t={t}
                    {...{ isButtonDisabled, setIsButtonDisabled }}
                  />
                }
              />
              <Route
                path="/pallet-scan"
                element={
                  <Pricing
                    t={t}
                    {...{ isButtonDisabled, setIsButtonDisabled }}
                  />
                }
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      ) : (
        <NoInternet />
      )}
    </>
  )
}

export default App
