import './style.scss'
import noInternet from '@assets/images/no-internet.png'

const NoInternet = () => {
  return (
    <div className="no-internet-page">
      <img src={noInternet} width={250} />
      <h1>Connection lost!</h1>
      <p>Please check your internet connection</p>
    </div>
  )
}

export default NoInternet
