import { getUnicode } from '@helpers/index'
import { setNotification } from '@store/Slices/utilitySlice'
import store from '@store/index'
import axios, { type AxiosInstance } from 'axios'
import { trackEvent } from '../components/common/AppInsights/AppInsightsConfig'

export const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 20000,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Headers': '*',
    // 'content-type': 'application/json',
    source_system: 'web-application',
    source_channel: 'HTTPS',
    target_system: 'DMS',
    target_channel: 'DB',
    request_identifier: getUnicode(),
    correlation_id: getUnicode(),
    scope: 'READ',
  },
})
// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const basicAuth = sessionStorage.getItem('Authorization')
    config.headers.request_identifier = getUnicode()
    config.headers.correlation_id = getUnicode()

    if (basicAuth) {
      config.headers.Authorization = `Bearer ${basicAuth}`
    }
    return config
  },
  async (error) => {
    console.log(error, 'API error')
    return await Promise.reject(error)
  }
)

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response: any) => {
    console.log('response.data', response.data)
    if (!response.request.fromCache) {
      const {
        config: { url, method, data },
        status,
      } = response
      trackEvent({
        url,
        httpMethod: method.toUpperCase(),
        httpStatusCode: status,
        payload: data || null,
        response: JSON.stringify(response.data),
        success: true,
      })
    }
    if (response.data) return response.data
    return Promise.reject(response)
  },
  async (error) => {
    console.log(error, 'API error', error)
    return await Promise.reject(error)
  }
)

export const clearAxiosCache = () => {
  try {
    sessionStorage.removeItem('Authorization') // Remove the cache from AsyncStorage
    axiosClient.defaults.headers.common['Cache-Control'] = 'no-cache' // Add a 'no-cache' header to subsequent requests
  } catch (error) {
    console.log('Error clearing Axios cache:', error)
  }
}

async function getCliendId() {
  const headers = {
    scope: 'READ',
    client_name: 'ReadyStore',
    grant_type: 'CLIENT_CREDENTIALS',
  }
  const response: any = await getRequest(
    process.env.REACT_APP_API_ENDPOINT as string,
    {
      headers,
    }
  )
  if (response) {
    console.log(response)
    if (response.client_id !== '' && response.client_secret !== '') {
      // Keychain.setGenericPassword(response.client_id, response.client_secret)
      // const credentials = Keychain.getGenericPassword()
      // console.log(credentials)
    }
  }
}

async function getRequest(
  URL: string,
  additionalHeaders?: object,
  baseURL?: string
) {
  try {
    if (baseURL) {
      // In case baseURL is different
      axiosClient.defaults.baseURL = baseURL
      axiosClient.defaults.timeout = 1000
      setTimeout(() => {
        axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // to set back baseURL to original
      }, 10)
    }
    if (URL.includes('/pricing/details')) {
      const upcTimeout: any = process.env.REACT_APP_UPC_TIMEOUT
      axiosClient.defaults.timeout = parseInt(upcTimeout) || 5000
      axiosClient.defaults.baseURL = process.env.REACT_APP_UPC_URL
      setTimeout(() => {
        axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // to set back baseURL to original
      }, 10)
    }
    console.log('calling GET API ', URL, additionalHeaders)
    const response = await axiosClient
      .get(URL, additionalHeaders)
      .then((response) => {
        // Handle the successful response here
        return response
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED' && URL.includes('/pricing/details')) {
          // Handle the timeout error here
          store.dispatch(
            setNotification({
              notification:
                'Please rescan again after scanning few more items.',
              variant: 'error',
            })
          )
        } else if (
          error.code === 'ERR_BAD_RESPONSE' &&
          URL.includes('/pricing/details')
        ) {
          // Handle other errors
          store.dispatch(
            setNotification({
              notification: 'Timeout reached, please retry after sometime',
              variant: 'error',
            })
          )
          console.error(error)
        } else {
          store.dispatch(
            setNotification({
              notification: 'Timeout reached, please retry after sometime',
              variant: 'error',
            })
          )
        }
      })
      .catch((customError) => {
        // Handle the custom error with your custom message
        console.error(customError.message)
      })
    console.log(URL, '==========>', response)
    return response
  } catch (error) {
    return error
  }
}

async function postRequest(
  URL: string,
  payload: object,
  additionalHeaders?: object,
  baseURL?: string
) {
  try {
    if (baseURL) {
      // In case baseURL is different
      axiosClient.defaults.baseURL = baseURL
      axiosClient.defaults.timeout = 1000
      setTimeout(() => {
        axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // to set back baseURL to original
      }, 10)
    }
    console.log('calling POST API ', URL, additionalHeaders)
    let response
    if (additionalHeaders) {
      response = await axiosClient.post(URL, payload, additionalHeaders)
    } else {
      response = await axiosClient.post(URL, payload)
    }
    console.log(URL, '==========>', response)
    return response
  } catch (error) {
    return error
  }
}

async function patchRequest(
  URL: string,
  payload: object,
  additionalHeaders?: object,
  baseURL?: string
) {
  try {
    if (baseURL) {
      // In case baseURL is different
      axiosClient.defaults.baseURL = baseURL
      axiosClient.defaults.timeout = 1000
      setTimeout(() => {
        axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // to set back baseURL to original
      }, 10)
    }
    console.log('calling POST API ', URL, additionalHeaders)
    let response
    if (additionalHeaders) {
      response = await axiosClient.patch(URL, payload, additionalHeaders)
    } else {
      response = await axiosClient.patch(URL, payload)
    }
    console.log(URL, '==========>', response)
    return response
  } catch (error) {
    return error
  }
}

async function delRequest(
  URL: string,
  additionalHeaders?: object,
  baseURL?: string
) {
  try {
    if (baseURL) {
      // In case baseURL is different
      axiosClient.defaults.baseURL = baseURL
      axiosClient.defaults.timeout = 1000

      setTimeout(() => {
        axiosClient.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT // to set back baseURL to original
      }, 10)
    }
    console.log('calling Delete API ', URL, additionalHeaders)
    const response = await axiosClient.delete(URL, additionalHeaders)
    console.log(URL, '==========>', response)
    return response
  } catch (error) {
    return error
  }
}

const setAuthorizationToken = (token: string) => {
  if (token) {
    sessionStorage.setItem('Authorization', token)
    axiosClient.defaults.headers.authorization = `Bearer ${token}`
  }
}

const removeAuthorizationToken = () => {
  sessionStorage.removeItem('Authorization')
}

export {
  getRequest,
  postRequest,
  setAuthorizationToken,
  removeAuthorizationToken,
  delRequest,
  patchRequest,
  getCliendId,
}

export default axiosClient
