import { createSlice } from '@reduxjs/toolkit'

const pricingSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    palletInfo: {},
    processPalletInfo: {},
    upcInfo: {},
    LetterClothingUPCInfo: {},
    barcodeInfo: {},
    selectedCategory: {},
    selectedTeamNumber: null,
    selectedLetterClothingCategory: {},
    productivityCount: {},
    productivityLoader: false,
    looseItemsCount: { acc: 0, dom: 0 },
    productivitylastLoginTime: null,
  },
  reducers: {
    setPalletInfo: (state: any, action: any) => {
      state.palletInfo = action.payload
    },
    removePalletData: (state: any) => {
      state.palletInfo = {}
    },
    setProcessPalletInfo: (state: any, action: any) => {
      state.processPalletInfo = action.payload
    },
    removeProcessPalletInfo: (state: any) => {
      state.processPalletInfo = {}
    },
    setUPCInfo: (state: any, action: any) => {
      state.upcInfo = action.payload
    },
    removeUPCData: (state: any) => {
      state.upcInfo = {}
    },
    setLetterClothingUPCInfo: (state: any, action: any) => {
      state.LetterClothingUPCInfo = action.payload
    },
    removeLetterClothingUPCInfo: (state: any) => {
      state.LetterClothingUPCInfo = {}
    },
    setCateory: (state: any, action: any) => {
      state.selectedCategory = action.payload
    },
    removeCateory: (state: any) => {
      state.selectedCategory = {}
    },

    setBarcodeInfo: (state: any, action: any) => {
      state.barcodeInfo = action.payload
    },
    removeBarcodeInfo: (state: any) => {
      state.barcodeInfo = {}
    },
    setSelectedTeamNumber: (state: any, action: any) => {
      state.selectedTeamNumber = action.payload
    },
    removeSelectedTeamNumber: (state: any) => {
      state.selectedTeamNumber = null
    },
    setSelectedLetterClothingCategory: (state: any, action: any) => {
      state.selectedLetterClothingCategory = action.payload
    },
    removeSelectedLetterClothingCategory: (state: any) => {
      state.selectedLetterClothingCategory = {}
    },
    setProductivityCount: (state: any, action: any) => {
      state.productivityCount = action.payload
    },
    setProductivitylastLoginTime: (state: any, action: any) => {
      state.productivitylastLoginTime = action.payload
    },
    removeProductivitylastLoginTime: (state: any) => {
      state.productivitylastLoginTime = null
      state.productivityCount = {}
    },
    setProductivityLoader: (state: any, action: any) => {
      state.productivityLoader = action.payload
    },
    updateLooseItemsCount: (state: any, action: any) => {
      state.looseItemsCount = {
        ...state.looseItemsCount,
        [action.payload]: state.looseItemsCount[action.payload] + 1,
      }
    },
    resetLooseItemsCount: (state: any) => {
      state.looseItemsCount = { acc: 0, dom: 0 }
    },
  },
})

export const {
  setPalletInfo,
  removePalletData,
  setProcessPalletInfo,
  removeProcessPalletInfo,
  setBarcodeInfo,
  removeBarcodeInfo,
  setSelectedTeamNumber,
  removeSelectedTeamNumber,
  setUPCInfo,
  removeUPCData,
  setCateory,
  removeCateory,
  setLetterClothingUPCInfo,
  removeLetterClothingUPCInfo,
  setSelectedLetterClothingCategory,
  removeSelectedLetterClothingCategory,
  setProductivityCount,
  setProductivitylastLoginTime,
  removeProductivitylastLoginTime,
  setProductivityLoader,
  updateLooseItemsCount,
  resetLooseItemsCount,
} = pricingSlice.actions
export default pricingSlice.reducer
