import './style.scss'

interface tabButtonPropTypes {
  buttonText: string
  isActive: boolean
  className: string
}

export default function TabButton({
  buttonText,
  isActive,
  className,
}: tabButtonPropTypes) {
  return (
    <div className={`tab_row ${className}`}>
      <div className={`tab-common ${isActive ? 'tab-btn-active' : 'tab-btn'}`}>
        {buttonText}
      </div>
    </div>
  )
}
