import { useDispatch } from 'react-redux'
import './style.scss'
import { alphabets } from '@helpers/index'
import { setSelectedVendor } from '@store/Slices/dashboard'
import type { vendorList } from '../../../../types/dashboard'
import { updateFrequentSearchVendors } from '@store/Slices/persistedDataSlice'
import { useNavigate } from 'react-router-dom'

export default function Vendors(props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    vendorList,
    frequentSearchVendors,
    t,
    searchKeywords,
    vendorListOriginal,
  } = props
  return (
    <div className="vendor_list_wrapper">
      {frequentSearchVendors.length && !searchKeywords ? (
        <>
          <div className="alphabat_row">
            <div className="alphabet"></div>
            <div>
              <div className="frequent_search">{t('Frequent_search')}</div>
              <div className="vendors_row">
                {frequentSearchVendors?.map((vendor: vendorList) => (
                  <div
                    key={vendor.ID}
                    className="vendor_name"
                    onClick={() => {
                      dispatch(setSelectedVendor(vendor))
                      navigate('/pallet-scan')
                    }}
                  >
                    {vendor?.VENDORNAME}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        ''
      )}

      <div className="vendor_container">
        {Object.keys(vendorList) &&
          alphabets.map((item) => (
            <div key={item}>
              {vendorList[item]?.filter((vendor: any) =>
                vendor.VENDORNAME.toLowerCase().includes(
                  searchKeywords.toLowerCase()
                )
              )?.length ? (
                <div className="alphabat_row" key={item}>
                  <div className="alphabet">{item}</div>
                  <div className="vendors_row">
                    {vendorList[item]
                      ?.filter((vendor: any) =>
                        vendor.VENDORNAME.toLowerCase().includes(
                          searchKeywords.toLowerCase()
                        )
                      )
                      .map((vendor: any) => (
                        <div
                          key={vendor.ID}
                          className="vendor_name"
                          onClick={() => {
                            dispatch(setSelectedVendor(vendor))
                            navigate('/pallet-scan')
                            if (
                              !frequentSearchVendors.some(
                                (item: vendorList) => item.ID === vendor.ID
                              )
                            ) {
                              dispatch(updateFrequentSearchVendors(vendor))
                            }
                          }}
                        >
                          {vendor?.VENDORNAME}
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          ))}

        {!vendorListOriginal?.filter((vendor: any) =>
          vendor.VENDORNAME.toLowerCase().includes(searchKeywords.toLowerCase())
        ).length && <p className="no_vendor_msg">{t('no_results')}</p>}
      </div>
    </div>
  )
}
