import './style.scss'

export default function Breadcrumb({ items }: any) {
  return (
    <div className="breadcrumb_row">
      {items.map((item: string, index: number) => (
        <div className="breadcrumb-item" key={item}>
          {item} {index === items.length - 1 ? '' : '/'}
        </div>
      ))}
    </div>
  )
}
