import { Modal } from 'rsuite'

interface DupicateTabPropTypes {
  duplicateTabs: boolean
}

export default function DuplicateTabModal({
  duplicateTabs,
}: DupicateTabPropTypes) {
  return (
    <Modal open={duplicateTabs}>
      <Modal.Header closeButton={false}>
        <Modal.Title>Duplicate Tabs Warning!</Modal.Title>
      </Modal.Header>
      <Modal.Body>One more page already open for CCM Pricing</Modal.Body>
    </Modal>
  )
}
