import './style.scss'
import Notfound from '@assets/images/page-not-found.png'

const PageNotFound = () => {
  return (
    <div className="not-found-page">
      <img src={Notfound} width={250} />
      <h1>Page Not Found</h1>
      <p>The requested page could not be found.</p>
    </div>
  )
}

export default PageNotFound
