import { configureStore } from '@reduxjs/toolkit'
import persistedReducer from './Slices'
import thunk from 'redux-thunk'
import { loggerMiddleware } from './middlewares/logger'
import { persistStore } from 'redux-persist'

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk, loggerMiddleware],
  devTools:
    process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'uat',
})

const persistor = persistStore(store)

export default store
export { persistor }
