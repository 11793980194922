import './style.scss'

interface customBtnPropTypes {
  buttonText: string
  isActive?: boolean
  className?: string
  onClick?: any
  activeColor?: string
  activeTextColor?: string
  darkerText?: boolean
  width?: string
  styles?: any
  btnStyles?: any
}

export default function CustomButton({
  buttonText,
  isActive,
  className,
  onClick,
  activeColor,
  activeTextColor,
  darkerText = true,
  width,
  styles,
  btnStyles,
}: customBtnPropTypes) {
  return (
    <div
      className={`btn_row ${className || ''}`}
      onClick={isActive ? onClick : null}
      style={styles}
    >
      <div
        className={`btn-common ${
          isActive ? 'custom-btn-active' : 'custom-btn'
        }`}
        style={{
          backgroundColor: activeColor && activeColor,
          color: activeTextColor && activeTextColor,
          fontWeight: !darkerText ? '500' : '600',
          width: width && width,
          ...btnStyles,
        }}
      >
        {buttonText}
      </div>
    </div>
  )
}
