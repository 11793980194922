import { Input } from 'rsuite'
import './style.scss'
import { setNotification } from '@store/Slices/utilitySlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const CustomInput = ({
  id,
  width = '262px',
  onChange,
  style,
  bgColor,
  textColor,
  value,
  type,
  inputType,
  isPassword,
  ...rest
}: any) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const notAllowed = /^[A-Z@~`!@#$%^&*()_|[{}\]=+\\\\';:"\\/?>.<,-]*$/i
  const allowed = /^[0-9]*$/i
  const priceRegex = /^[0-9]\d*(\.\d+)?$/i
  return (
    <Input
      id={id}
      className="base_input"
      onKeyPress={(event: any) => {
        if (
          type === 'number' &&
          (inputType === 'decimal'
            ? !priceRegex.test(event.key)
            : !allowed.test(event.key))
        ) {
          event.preventDefault()
        }
      }}
      onPaste={(event: any) => {
        const pastedValue = event.clipboardData.getData('text')

        if (type === 'number' && !allowed.test(pastedValue)) {
          dispatch(
            setNotification({
              notification: t('Only numeric values are allowed'),
              variant: 'error',
            })
          )
          event.preventDefault()
        }
      }}
      {...rest}
      value={value}
      style={{
        ...style,
        width,
        backgroundColor: bgColor && bgColor,
        color: textColor && textColor,
      }}
      type={isPassword && 'password'}
      onChange={onChange}
    />
  )
}

export default CustomInput
