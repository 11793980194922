import './style.scss'
import CustomButton from '@components/CustomButton'
import { InputGroup } from 'rsuite'
import Close from '@img/close.svg'
import CustomInput from '@components/Input'

const PasswordPopup = ({
  okButtonAction,
  cancelButtonAction,
  enterdPassword,
  setEnterdPassword,
}: any) => {
  return (
    <div className="loader-container">
      <div className="popup-layer macy-popup">
        <div className="popup-header">
          <p className="popup-headline">Supervisor Passcode</p>
          <InputGroup.Addon
            style={{ backgroundColor: 'transparent', border: '0px' }}
          >
            <img
              src={Close}
              className="input_icon"
              onClick={cancelButtonAction}
            />
          </InputGroup.Addon>
        </div>

        <>
          <div className="sub-headline-macy">Enter Supervisor Passcode</div>

          <CustomInput
            isPassword
            onChange={(e: any) => {
              console.log('e', e)
              setEnterdPassword(e)
            }}
            value={enterdPassword}
            onKeyDown={(event: any) => {
              if (event.keyCode === 13 || event.key === 'Enter') {
                okButtonAction()
              }
            }}
          />

          <div className="popup-password-btns">
            <CustomButton
              buttonText="Ok"
              width="100px"
              activeColor={'#D5D1D1'}
              activeTextColor="#24272C"
              onClick={okButtonAction}
              isActive
            />
          </div>
        </>
      </div>
    </div>
  )
}

export default PasswordPopup
