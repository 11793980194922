import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import BathRoom from '@img/TemplateDOM/bath_room.svg'
import BedRoom from '@img/TemplateDOM/bed_room.svg'

import pillow from '@img/TemplateDOM/part1/pillow.svg'
import memory_form from '@img/TemplateDOM/part1/memory_form.svg'
import pillow_case from '@img/TemplateDOM/part1/pillow_case.svg'
import throw_blanket from '@img/TemplateDOM/part1/throw_blanket.svg'
import matteress_topper from '@img/TemplateDOM/part1/matteress_topper.svg'
import king_sheet from '@img/TemplateDOM/part1/king_sheet.svg'
import twin_sheet from '@img/TemplateDOM/part1/twin_sheet.svg'
import mattress_protector from '@img/TemplateDOM/part1/mattress_protector.svg'
import king_conforter from '@img/TemplateDOM/part1/king_conforter.svg'
import queen_comforter from '@img/TemplateDOM/part1/queen_comforter.svg'
import twin_comforter from '@img/TemplateDOM/part1/twin_comforter.svg'
import queen_full_sheet from '@img/TemplateDOM/part1/queen_full_sheet.svg'

import hand_towel from '@img/TemplateDOM/part2/hand_towel.svg'
import towel from '@img/TemplateDOM/part2/towel.svg'
import xl_towel from '@img/TemplateDOM/part2/xl_towel.svg'
import bath_kitchen_mat from '@img/TemplateDOM/part2/bath_kitchen_mat.svg'
import dish_towel from '@img/TemplateDOM/part2/dish_towel.svg'
import shower_curtain from '@img/TemplateDOM/part2/shower_curtain.svg'
import seat_cover from '@img/TemplateDOM/part2/seat_cover.svg'
import table_cloth from '@img/TemplateDOM/part2/table_cloth.svg'
import curtai from '@img/TemplateDOM/part2/curtain.svg'
import throw_pillow from '@img/TemplateDOM/part2/throw_pillow.svg'

import Card from '@root/src/components/Card'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import Loader from '@components/Loader'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import CustomPopup from '@root/src/components/CustomPopup'
import { setNotification } from '@store/Slices/utilitySlice'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'
export default function TemplateDom({
  TEMPLATE_DOM,
  selectedVendor,
  selectedDOMParentCard,
  setSelectedDOMParentCard,
  selectedDOMChildCard,
  setSelectedDOMChildCard,
  showPopup,
  setShowPopup,
  t,
  addDamageItem,
}: any) {
  const { getBarcodeData } = useClothingEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo, selectedCategory } = useSelector(
    (store: any) => store.pricing
  )
  const { barcodeInfo } = useSelector((store: any) => store.pricing)
  const [qnt, setQnt] = useState<string>('1')
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  const dispatch = useDispatch()

  const svgImgsPart1 = [BathRoom, BedRoom]
  const svgImgsPart2 = [
    pillow,
    memory_form,
    pillow_case,
    throw_blanket,
    king_sheet,
    queen_full_sheet,
    twin_sheet,
    mattress_protector,
    king_conforter,
    queen_comforter,
    twin_comforter,
    matteress_topper,
  ]
  const svgImgsPart3 = [
    hand_towel,
    towel,
    xl_towel,
    bath_kitchen_mat,
    shower_curtain,
    seat_cover,
    table_cloth,
    curtai,
    dish_towel,
    throw_pillow,
  ]

  const loader = useSelector((store: any) => store.utility.loader)

  function getBarcodeDetailsAndPrint(selectedItem: any) {
    getBarcodeData(
      102,
      parseInt(qnt),
      TEMPLATE_DOM?.header?.sub_cat[0]?.stock_2_cat_id,
      '',
      '' + personData?.personName + '' + personData?.lastName,
      personData?.personId,
      palletInfo?.RawPalletId,
      'template_dom',
      '' + selectedItem?.cat_id,
      'Domestics',
      selectedItem.description.join(' ')
    )
  }

  return (
    <div className="clothing-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-30'),
                  variant: 'warning',
                })
              )
              return
            }
            setShowPopup(false)
            getBarcodeDetailsAndPrint(selectedDOMChildCard)
            setQnt('1')
          }}
          cancelButtonAction={(e: any) => {
            setShowPopup(false)
          }}
        />
      )}

      <div className="left-container">
        <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Template DOM']} />
        {TEMPLATE_DOM?.category?.length ? (
          <div className="clothing-cards">
            {/* FIRST SECTION */}
            <div className="adult-section">
              {TEMPLATE_DOM.category
                .slice(0, 2)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    style={{ height: '115px' }}
                    info={item.description}
                    translations={TEMPLATE_DOM?.translation}
                    imgURI={svgImgsPart1[index]}
                    imgAlt={item.cat_id}
                    onClick={() => {
                      setSelectedDOMParentCard(item.cat_id)
                    }}
                    imgWidth={70}
                    selectedCard={item.cat_id === selectedDOMParentCard}
                  />
                ))}
            </div>

            {/* SECOUND SECTION */}
            <div className="child-section">
              {TEMPLATE_DOM?.category
                .slice(2)
                ?.filter((cat: any) => cat.parent_id === selectedDOMParentCard)
                .slice(0, selectedDOMParentCard === 4560 ? 6 : 5)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    style={{ height: '78px' }}
                    info={item.description}
                    translations={TEMPLATE_DOM?.translation}
                    imgURI={
                      selectedDOMParentCard === 4560
                        ? svgImgsPart2[index]
                        : svgImgsPart3[index]
                    }
                    imgWidth={70}
                    onClick={() => {
                      setShowPopup(true)
                      setSelectedDOMChildCard(item)
                    }}
                    selectedCard={item === selectedDOMChildCard}
                  />
                ))}
            </div>

            {/* THIRD SECTION */}
            <div className="adult-swimwear-section">
              {TEMPLATE_DOM.category
                .slice(2)
                ?.filter((cat: any) => cat.parent_id === selectedDOMParentCard)
                .slice(selectedDOMParentCard === 4560 ? 6 : 5)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    style={{ height: '78px' }}
                    info={item.description}
                    translations={TEMPLATE_DOM?.translation}
                    imgURI={
                      selectedDOMParentCard === 4560
                        ? svgImgsPart2[index + 6]
                        : svgImgsPart3[index + 5]
                    }
                    imgWidth={70}
                    onClick={() => {
                      setShowPopup(true)
                      setSelectedDOMChildCard(item)
                    }}
                    selectedCard={item === selectedDOMChildCard}
                  />
                ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {TEMPLATE_DOM?.category?.length && <hr className="clothing-hr" />}

      {TEMPLATE_DOM?.category?.length ? (
        <InfoSidebarPricing
          stockName={TEMPLATE_DOM?.header?.main_cat?.stock_name}
          subCat={TEMPLATE_DOM?.header?.sub_cat[0]?.sub_cat_name}
          mainCat={selectedSubCategory}
          stock2Cat={TEMPLATE_DOM?.header?.sub_cat[0]?.stock_2_cat_id}
          Stock={TEMPLATE_DOM?.header?.main_cat?.stock_number}
          priceRange={selectedCategory?.price_range}
          price={barcodeInfo?.price || ''}
          hidePriceRange={true}
          addDamageItem={addDamageItem}
        />
      ) : (
        ''
      )}
    </div>
  )
}
