import { createSlice } from '@reduxjs/toolkit'
import type {
  persistedDataInterface,
  persistedPayloadInterface,
  printZplInterface,
  printZplStateInterface,
  selectedPrinterInterface,
  selectedPrinterStateInterface,
} from '@root/src/types/other'
import type { loginInfoInterface } from '@root/src/types/person_types'
import type { vendorInterface } from '@root/src/types/dashboard'

const persistedDataSlice = createSlice({
  name: 'persistedData',
  initialState: {
    warehouseData: [],
    loginInfo: {
      badgeID: '',
      selectedWarehouse: '',
      selectedSideMenu: '',
    },
    frequentSearchVendors: [],
    selectedLang: 'en',
    printZplString: null,
    printValues: '',
    showNotification: true,
    selectedPrinter: null,
    userProductivityInfo: {},
  },
  reducers: {
    setSelectedPrinter: (
      state: selectedPrinterStateInterface,
      action: selectedPrinterInterface
    ) => {
      state.selectedPrinter = action.payload.selectedPrinter
    },
    setPrintZplData: (
      state: printZplStateInterface,
      action: printZplInterface
    ) => {
      state.printZplString = action.payload.printZplString
      state.printValues = action.payload.printValues
      state.showNotification = action.payload.showNotification
    },

    setWarehouseData: (
      state: persistedDataInterface,
      action: persistedPayloadInterface
    ) => {
      state.warehouseData = action.payload
    },
    removeWarehouseData: (state: persistedDataInterface) => {
      state.warehouseData = []
    },
    setLoginInfo: (
      state: persistedDataInterface,
      action: loginInfoInterface
    ) => {
      state.loginInfo = action.payload
    },
    setSelectedLang: (
      state: persistedDataInterface,
      action: persistedPayloadInterface
    ) => {
      state.selectedLang = action.payload
    },
    updateFrequentSearchVendors: (
      state: persistedDataInterface,
      action: vendorInterface
    ) => {
      if (state.frequentSearchVendors.length >= 10) {
        state.frequentSearchVendors.pop()
      }
      state.frequentSearchVendors.unshift(action.payload) // to add element at the beggining
    },
    setUserProductivityInfo: (state: any, action: any) => {
      state.userProductivityInfo = action.payload
    },
    removeUserProductivityInfo: (state: any) => {
      state.userProductivityInfo = {}
    },
  },
})

export const {
  setWarehouseData,
  removeWarehouseData,
  updateFrequentSearchVendors,
  setLoginInfo,
  setSelectedLang,
  setPrintZplData,
  setSelectedPrinter,
  removeUserProductivityInfo,
  setUserProductivityInfo,
} = persistedDataSlice.actions
export default persistedDataSlice.reducer
