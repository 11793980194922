import { getRequestIdentifier, validateString } from '@helpers/index'
import api_endpoints from '@services/api_endpoints'
import { getRequest, postRequest } from '@services/axios'
import { setCategoryList } from '@store/Slices/dashboard'
import { setPrintZplData } from '@store/Slices/persistedDataSlice'
import {
  removePalletData,
  removeProcessPalletInfo,
  setPalletInfo,
  setProcessPalletInfo,
  updateLooseItemsCount,
} from '@store/Slices/pricing'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

export default function usePalletEnhancer({
  scanTemplateData,
  setScanTemplateData,
}: any) {
  const { selectedVendor, categoryList } = useSelector(
    (store: any) => store.dashboard
  )
  const { personData } = useSelector((store: any) => store.personData)
  const { looseItemsCount } = useSelector((store: any) => store.pricing)
  const { loginInfo } = useSelector((store: any) => store.persistedData)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  async function getCategoryList() {
    const basicAuth = sessionStorage.getItem('Authorization')

    if (!basicAuth) {
      return
    }
    dispatch(setLoader(true))

    const response: any = await getRequest(api_endpoints.getcategory(), {
      headers: {
        transaction_type: getRequestIdentifier(
          'category',
          personData?.personId,
          loginInfo?.selectedWarehouse,
          ''
        ),
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
      },
    })

    console.log('Response', response)
    if (response?.success) {
      dispatch(setCategoryList(response.data[0]))
      console.log('categoryList', categoryList)
      dispatch(setLoader(false))
    } else {
      dispatch(setLoader(false))
      dispatch(
        setNotification({
          notification: response?.message,
          variant: 'error',
          translation: response?.translation ?? null,
        })
      )
    }
  }
  async function getPalletDetails(
    palletID: number,
    userName: string,
    teamNumber: number,
    isRawPalletScan: boolean
  ) {
    if (!palletID) {
      dispatch(
        setNotification({
          notification: t('Please enter pallet ID'),
          variant: 'warning',
        })
      )
      return
    } else if (!validateString(`${palletID}`)) {
      dispatch(
        setNotification({
          notification: t('Please scan a valid pallet'),
          variant: 'warning',
        })
      )
      return
    }
    dispatch(setLoader(true))

    getCategoryList()
    const palletResponse: any = await getRequest(
      api_endpoints.validatePallate(
        palletID,
        userName,
        teamNumber,
        selectedVendor?.ID
      ),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'PalletStatus',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            palletID
          ),
          action_type: isRawPalletScan ? 'raw-pallet' : 'processed-pallet',
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        },
      }
    )
    function camelize(str: any) {
      return str.replace(
        /(?:^\w|[A-Z]|\b\w)/g,
        function (word: any, index: any) {
          return index === 0 ? word.toUpperCase() : word.toLowerCase()
        }
      )
    }
    if (palletResponse?.success && palletResponse?.data?.length) {
      if (
        isRawPalletScan &&
        selectedVendor.ID < 150 &&
        selectedVendor?.VENDORNAME !== 'ALL OTHER VENDORS' &&
        !palletResponse?.data[0]?.stockName.includes(
          selectedVendor?.VENDORNAME.split(' ')[0]
        ) &&
        !palletResponse?.data[0]?.stockName.includes(
          selectedVendor?.VENDORNAME.split('_')[0]
        ) &&
        !selectedVendor?.VENDORNAME.includes(
          palletResponse?.data[0]?.stockName.split(' ')[0]
        ) &&
        !selectedVendor?.VENDORNAME.includes(
          palletResponse?.data[0]?.stockName.split('_')[0]
        ) &&
        !selectedVendor?.VENDORNAME.includes(
          palletResponse?.data[0]?.stockName.split("'")[0]
        )
      ) {
        dispatch(
          setNotification({
            notification: t(
              'This pallet does not belong to the selected vendor'
            ),
            variant: 'error',
          })
        )
        dispatch(setLoader(false))
        isRawPalletScan
          ? dispatch(removePalletData())
          : dispatch(removeProcessPalletInfo())

        return
      }

      dispatch(setLoader(false))
      if (isRawPalletScan) {
        dispatch(setPalletInfo(palletResponse?.data[0]))
        dispatch(
          setNotification({
            notification: t('Raw Pallet found, successfully deleted'),
            variant: 'success',
          })
        )
      } else {
        console.log(
          palletResponse?.data[0]?.mainCategory,
          'processPalletInfo?.mainCategory'
        )
        dispatch(setProcessPalletInfo(palletResponse?.data[0]))
        dispatch(
          setNotification({
            notification: palletResponse?.message,
            variant: 'success',
          })
        )
      }
    } else {
      dispatch(setLoader(false))
      isRawPalletScan
        ? dispatch(removePalletData())
        : dispatch(removeProcessPalletInfo())
      dispatch(
        setNotification({
          notification: camelize(palletResponse?.message),
          variant: 'error',
          translation: palletResponse?.translation ?? null,
        })
      )
    }
  }

  async function printToteLabel(
    palletID: number,
    stockId: number,
    stockName: string
  ) {
    dispatch(setLoader(true))

    const response: any = await getRequest(
      api_endpoints.gettoteLabel(palletID, stockId, stockName),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'damage-item',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            palletID
          ),
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          action_type: 'template-scan',
        },
      }
    )
    dispatch(setLoader(false))

    if (response?.success) {
      const barcodeID = '' + response?.data?.tote_id ?? 0.0
      const barcodePadded = barcodeID.padStart(10, '0')
      const barcodeIDconverted =
        barcodePadded.slice(0, 3) +
        '-' +
        barcodePadded.slice(3, 6) +
        '-' +
        barcodePadded.slice(6, 10)
      const barcodeZPL = `^XA
^CE28
^FS~JS 
^LT0
^MNW
^MTT
^PON
^PMN
^LH0,0
^JMA
^PR4,4
^JUS
^LRN
^CI27
^PA0,1,1,0
^MMT
^PW406
^LL243
^LS0
^FT210,80
^A0N,25,25
^FH^CI28
^FDTote Label
^FS
^CI27
^FPH,1
^FT210,110
^A0N,17,17
^FH^CI28
^FDRaw PalletID#
^FS
^CI27
^FPH,1
^FT260,135
^A0N,20,15
^FH^CI28
^FN1^FD${barcodeID}&P:${palletID}&S:${stockId} &SN: ${stockName}
^CI27
^FT15,190
^BON,6,N,0,N,1,
^FH^FN2bCODE
^FS
^FPH,1
^FT210,140
^A0N,17,17
^FH^CI28
^FN3^FD
^FS
^CI27
^FPH,1
^FT210,155
^A0N,17,17
^FH^CI28
^FDStock#
^FS
^FPH,1
^FT210,180
^A0N,17,18
^FH^CI28
^FN4^FD${stockId}
^FS
^FPH,1
^FT210,135
^A0N,17,17
^FH^CI28
^FD${palletID}
^FS
^CI27
^FO364,205
^FS
^LRY
^FT210,210
^A0N,20,20
^FH^CI28
^FN5^FD${barcodeIDconverted}
^FS
^CI27
^LRN
^XZ`
      console.log('BARCODE DATA', barcodeZPL)

      dispatch(
        setPrintZplData({
          printZplString: true,
          printValues: barcodeZPL,
          showNotification: true,
        })
      )
    } else {
      dispatch(
        setNotification({
          notification: response?.message,
          variant: 'error',
          translation: response?.translation ?? null,
        })
      )
    }
  }

  async function scanTemplate(palletID: number) {
    if (!palletID) {
      dispatch(
        setNotification({
          notification: 'Please enter pallet id',
          variant: 'warning',
        })
      )
      return
    } else if (!validateString(`${palletID}`)) {
      dispatch(
        setNotification({
          notification: t('Please scan a valid pallet'),
          variant: 'warning',
        })
      )
      return
    }
    dispatch(setLoader(true))

    const palletResponse: any = await getRequest(
      api_endpoints.scanTemplate(palletID),
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'Loose-Dom-And-Acc',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            palletID
          ),
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
          action_type: 'template-scan',
        },
      }
    )
    dispatch(setLoader(false))
    if (palletResponse?.data[0]?.description === 'ACCESSORIES TEMPLATE') {
      dispatch(
        setNotification({
          notification: palletResponse?.message,
          variant: 'success',
          translation: palletResponse?.translation ?? null,
        })
      )
      setScanTemplateData({
        ...scanTemplateData,
        acc: {
          status: true,
          palletId: palletID,
          desc: palletResponse?.data[0]?.description,
        },
      })
    } else if (palletResponse?.data[0]?.description === 'DOMESTICS TEMPLATE') {
      dispatch(
        setNotification({
          notification: palletResponse?.message,
          variant: 'success',
          translation: palletResponse?.translation ?? null,
        })
      )
      setScanTemplateData({
        ...scanTemplateData,
        dom: {
          status: true,
          palletId: palletID,
          desc: palletResponse?.data[0]?.description,
        },
      })
    } else {
      dispatch(
        setNotification({
          notification: palletResponse?.message,
          variant: 'error',
          translation: palletResponse?.translation ?? null,
        })
      )
    }
  }

  async function addLooseItems(looseObj: any) {
    dispatch(setLoader(true))
    const addItemData = {
      inputParams: {
        description: looseObj?.desc,
        pallet_id: parseInt(looseObj?.palletId),
        create_user: personData?.personName + '.' + personData?.lastName,
      },
    }

    const looseAPIResponse: any = await postRequest(
      api_endpoints.looseDomesticAccessories,
      addItemData,
      {
        headers: {
          transaction_type: getRequestIdentifier(
            'looseDomAcc',
            personData?.personId,
            loginInfo?.selectedWarehouse,
            looseObj?.palletId
          ),
          Authorization: 'Bearer ' + localStorage.getItem('Authorization'),
        },
      }
    )
    dispatch(setLoader(false))
    if (looseAPIResponse?.success) {
      const looseObjType: any =
        looseObj?.desc === 'DOMESTICS TEMPLATE' ? 'dom' : 'acc'
      dispatch(updateLooseItemsCount(looseObjType))

      dispatch(
        setNotification({
          notification: (looseAPIResponse?.message).replace(
            'X',
            looseItemsCount[looseObjType] + 1
          ),
          variant: 'success',
          translation: looseAPIResponse?.translation ?? null,
        })
      )
    } else {
      dispatch(
        setNotification({
          notification: looseAPIResponse?.message,
          variant: 'error',
          translation: looseAPIResponse?.translation ?? null,
        })
      )
    }
  }

  return {
    getPalletDetails,
    scanTemplate,
    addLooseItems,
    printToteLabel,
    // getCategoryList,
  }
}
