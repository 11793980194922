import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import CustomButton from '@components/CustomButton'
import CustomInput from '@components/Input'
import { InputGroup } from 'rsuite'
import InputScannerImg from '@img/input_scanner_dark.svg'
import EditCircle from '@root/src/components/EditCircle'
import useUPCScanEnhancer from './useUPCScanEnhancer'
import { removeUPCData } from '@store/Slices/pricing'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '@components/Loader'
import NoUPCPopup from '@root/src/components/CustomPopup/noUPC'
import { useEffect, useState } from 'react'
import { setNotification } from '@store/Slices/utilitySlice'
import MacyPopup from '@root/src/components/CustomPopup/macy'
import PasswordPopup from '@root/src/components/CustomPopup/password'

// import { calculateNewValue } from '@testing-library/user-event/dist/utils'

export default function UPCScan({
  selectedVendor,
  t,
  showPopup,
  setShowPopup,
  addDamageItem,
  showPriceEditPopup,
  setshowPriceEditPopup,
  option,
}: any) {
  const palletScannerStyle = {
    width: '262px',
  }
  const loader = useSelector((store: any) => store.utility.loader)
  const { barcodeInfo } = useSelector((store: any) => store.pricing)
  const {
    getItemDetails,
    palletInfo,
    selectedTeamNumber,
    upcInfo,
    updateUPCPrice,
    enabledPriceEdit,
    setEnabledPriceEdit,
    removeUPCText,
    setRemoveUPCText,
    getStandardPriceForUPC,
  } = useUPCScanEnhancer({ option })
  const dispatch = useDispatch()
  const [qnt, setQnt] = useState<string>('1')
  const [priceForMecy, setpriceForMecy] = useState<string>('')

  const [noUPCdescription, setNoUPCdescription] = useState<string>('')
  const [noUPCPrice, setNoUPCPrice] = useState<string>('')
  const [upcNumber, setUpcNumber] = useState<string>('')
  const [lastScannedupcNumber, setLastScannedUpcNumber] = useState<string>('')
  const [macyVendorPopup, setMacyVendorPopup] = useState<boolean>(false)
  const [passwordPopup, setPasswordPopup] = useState<boolean>(true)
  const [password, setPassword] = useState<string>('')

  // const [macyVendorSelectedCategory, setMacyVendorSelectedCategory] =
  //   useState<object>({})
  const printNoUPCbuttonEvent = () => {
    setShowPopup(true)
    dispatch(removeUPCData())
    setUpcNumber('')
  }
  const handleInputChange = (value: string) => {
    console.log('HANLDE DESCRIPTION INPUT', value)
    // limiting description allow characters
    if (value.length < 50) {
      setNoUPCdescription(value)
    }
  }
  useEffect(() => {
    if (enabledPriceEdit) {
      showPriceEditMethod()
    }
  }, [enabledPriceEdit])

  useEffect(() => {
    setUpcNumber(upcInfo?.upc)
    setPasswordPopup(true)
  }, [])
  useEffect(() => {
    if (removeUPCText) {
      setUpcNumber('')
      setRemoveUPCText(false)
    }
  }, [removeUPCText])

  useEffect(() => {
    if (upcInfo?.price) {
      setpriceForMecy('')
    }
    const inputupc = document.getElementById('UPCInput')
    inputupc?.focus()
  }, [upcInfo])

  function onCategorySelection(selectedObj: any) {
    setNoUPCdescription(selectedObj?.price)
    setNoUPCPrice(selectedObj?.price)
    setMacyVendorPopup(false)
    dispatch(removeUPCData())
    setUpcNumber('')
    getItemDetails(0, '' + selectedObj?.price, '' + selectedObj?.title, 1, true)
    setpriceForMecy('' + selectedObj?.price)
    // setMacyVendorSelectedCategory(selectedObj)
    // printNoUPCbuttonEvent()
  }

  function showPriceEditMethod() {
    setshowPriceEditPopup(true)
    setPasswordPopup(true)
    setNoUPCdescription('')
    setNoUPCPrice('')
  }
  function getPriceValue() {
    if (priceForMecy === '') {
      return upcInfo?.price ? Number(upcInfo?.price).toFixed(2) : ''
    } else {
      return priceForMecy
    }
  }
  useEffect(() => {
    const inputElement = document.getElementById('UPCInput')
    if (inputElement && showPopup) {
      inputElement.blur()
    }
  }, [showPopup])

  return (
    <div className="upc-container">
      {loader && <Loader />}

      {macyVendorPopup && (
        <MacyPopup
          headline={'Print Without UPC Scan'}
          cancelButtonAction={() => {
            setMacyVendorPopup(false)
          }}
          onCategorySelection={onCategorySelection}
        />
      )}

      {(showPopup || showPriceEditPopup) &&
        (showPriceEditPopup && passwordPopup ? (
          <PasswordPopup
            enterdPassword={password}
            setEnterdPassword={setPassword}
            okButtonAction={(e: any) => {
              if (password === process.env.REACT_APP_PRICE_EDIT_PASSWORD) {
                setPasswordPopup(false)
              } else {
                dispatch(
                  setNotification({
                    notification: t('Please enter valid password'),
                    variant: 'error',
                  })
                )
              }
              setPassword('')
            }}
            cancelButtonAction={(e: any) => {
              setshowPriceEditPopup(false)
            }}
          />
        ) : (
          <NoUPCPopup
            showDescField={true}
            headline={
              showPriceEditPopup ? 'Edit price' : 'Print without UPC scan'
            }
            upc={showPriceEditPopup ? lastScannedupcNumber : ''}
            quantity={parseInt(qnt)}
            priceValue={noUPCPrice}
            descVal={noUPCdescription}
            editPrice={showPriceEditPopup}
            onPriceChange={(e: any) => {
              setNoUPCPrice(e)

              // Ensure input only contains digits and at most one decimal point
              const sanitizedPrice = e.replace(/[^0-9.]/g, '')
              const decimalParts = sanitizedPrice.split('.')
              if (decimalParts.length > 2) {
                setNoUPCPrice(noUPCPrice)
                return
              }

              // Limit decimal places to two
              if (decimalParts[1] && decimalParts[1].length > 2) {
                setNoUPCPrice(noUPCPrice)
                return
              }

              // Limit decimal places to 4
              if (decimalParts[0] && decimalParts[0].length > 4) {
                setNoUPCPrice(noUPCPrice)
                return
              }

              setNoUPCPrice(sanitizedPrice)
            }}
            onChangeDesc={(e: any) => {
              if (!e) {
                setNoUPCdescription('')
              } else {
                handleInputChange(e)
              }
              if (e === '') {
                return
              }
              // Limit decimal places to 4
              if (e.length > 50) {
                setNoUPCdescription(noUPCdescription)
                return
              }
              setNoUPCdescription(e)
            }}
            onChangeQty={(e: any) => {
              if (!e) {
                setQnt('0')
              } else {
                setQnt(e)
              }
              if (e === '') {
                return
              }
              // Limit decimal places to 4
              if (e.length > 2) {
                setQnt(qnt)
                return
              }
              setQnt(e)
            }}
            standardPriceButtonAction={(e: any) => {
              console.log('STANDARD PRICE BUTTON PRESSED')
              if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
                dispatch(
                  setNotification({
                    notification: t('Please enter quantity between 1-30'),
                    variant: 'warning',
                  })
                )
                return
              }
              console.log('STANDARD PRICE BUTTON PRESSED')
              setShowPopup(false)
              setshowPriceEditPopup(false)
              getStandardPriceForUPC(parseInt(qnt))
            }}
            okButtonAction={(e: any) => {
              if (
                parseFloat(noUPCPrice) > 9999.99 ||
                parseFloat(noUPCPrice) < 0.01 ||
                noUPCPrice === '.' ||
                noUPCPrice === ''
              ) {
                dispatch(
                  setNotification({
                    notification: t(
                      'Please enter price between 0.01 - 9999.99'
                    ),
                    variant: 'warning',
                  })
                )
                return
              }

              if (noUPCPrice === '') {
                dispatch(
                  setNotification({
                    notification: t('Please enter the price'),
                    variant: 'warning',
                  })
                )
                return
              }
              // if (noUPCdescription === '') {
              //   dispatch(
              //     setNotification({
              //       notification: t('Please enter the description'),
              //       variant: 'warning',
              //     })
              //   )
              //   return
              // }
              if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
                dispatch(
                  setNotification({
                    notification: t('Please enter quantity between 1-30'),
                    variant: 'warning',
                  })
                )
                return
              }
              if (showPriceEditPopup) {
                updateUPCPrice(
                  lastScannedupcNumber,
                  noUPCPrice,
                  noUPCdescription,
                  parseInt(qnt)
                )
                setshowPriceEditPopup(false)
              } else {
                setShowPopup(false)
                getItemDetails(
                  0,
                  noUPCPrice,
                  noUPCdescription,
                  parseInt(qnt),
                  true
                )
              }
              setNoUPCPrice('')
              setNoUPCdescription('')
              setQnt('1')
            }}
            cancelButtonAction={(e: any) => {
              setShowPopup(false)
              setshowPriceEditPopup(false)
              setNoUPCPrice('')
              setNoUPCdescription('')
              setQnt('1')
            }}
          />
        ))}

      <div className="upc-wrapper">
        <div className="main-area">
          <div className="upc-breadcrumb">
            <Breadcrumb
              items={[selectedVendor?.VENDORNAME, 'Item/UPC Scan', 'Print']}
            />

            {/* TEAM NUMBER SECTION */}
            <div className="team-number-row">
              <label className="team-label team-number-label">
                Team Number
              </label>
              <CustomInput
                disabled
                value={selectedTeamNumber}
                textColor={'#000'}
              />

              <div className="pallet-id">
                <label className="team-label team-number-label raw-pallet-label">
                  Raw Pallet ID
                </label>
                <CustomInput
                  disabled
                  value={palletInfo?.RawPalletId}
                  textColor={'#000'}
                />
              </div>
            </div>
            <div className="team-number-row">
              <label className="team-label team-number-label">Stock Name</label>
              <CustomInput
                disabled
                value={palletInfo?.stockName}
                width={'713px'}
                textColor={'#000'}
              />
            </div>

            <hr className="input-saparator" />

            {/* SCAN PALLET AND STOCK NUMBER SECTION */}
            <div className="team-number-row stock-name">
              <label className="team-label">Scan UPC</label>
              <InputGroup inside style={palletScannerStyle}>
                <CustomInput
                  id="UPCInput"
                  step={1}
                  style={{ border: '3px solid #000000' }}
                  value={upcNumber}
                  onChange={(e: string) => {
                    setUpcNumber(e)
                    setLastScannedUpcNumber(e)
                    setEnabledPriceEdit(false)
                  }}
                  onKeyDown={(event: any) => {
                    if (loader) {
                      return
                    }

                    if (event.keyCode === 13 || event.key === 'Enter') {
                      getItemDetails(event.target.value)
                      setpriceForMecy('')
                      const inputElement = document.getElementById('UPCInput')
                      if (inputElement) {
                        inputElement.blur()
                      }
                    }
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === '~') {
                      event.preventDefault()
                    }
                  }}
                  onPaste={(event: any) => {
                    const pastedValue = event.clipboardData.getData('text')

                    if (pastedValue.includes('~')) {
                      dispatch(
                        setNotification({
                          notification: t('Only numeric values are allowed'),
                          variant: 'error',
                        })
                      )
                      event.preventDefault()
                    }
                  }}
                  onFocus={() => {
                    if (upcInfo?.price) {
                      setUpcNumber('')
                      // dispatch(removeUPCData())
                    }
                  }}
                />
                <InputGroup.Addon>
                  <img src={InputScannerImg} className="input_icon" />
                </InputGroup.Addon>
              </InputGroup>
              <div className="pallet-id">
                <label className="team-label team-number-label price-label">
                  Price
                </label>
                <InputGroup inside style={{ border: '1px solid #5b5a5a' }}>
                  <InputGroup.Addon style={{ fontSize: 22, paddingTop: 13 }}>
                    $
                  </InputGroup.Addon>
                  <CustomInput
                    style={{ paddingLeft: 28 }}
                    value={getPriceValue()}
                    disabled
                    textColor={'#000'}
                    width={'315px'}
                  />
                </InputGroup>
                {enabledPriceEdit && (
                  <div
                    className="edit-pencil"
                    onClick={() => {
                      showPriceEditMethod()
                    }}
                  >
                    <EditCircle />
                  </div>
                )}
              </div>
            </div>
            <div className="team-number-row">
              <label className="team-label">Description</label>
              <CustomInput
                disabled
                width={'713px'}
                value={upcInfo?.description ?? ''}
                textColor={'#000'}
              />
            </div>

            {/* ALL BUTTONS SECTION */}
            <div className="team-number-row">
              <label className="team-label"></label>
              <div className="all-btn-wrapper">
                <div className="upc-scan-buttons">
                  <CustomButton
                    buttonText={'Damage Item'}
                    className="upc-scan-btn"
                    onClick={addDamageItem}
                    isActive={
                      // upcInfo?.barcode_id || barcodeInfo?.barcode_id?.length
                      (barcodeInfo?.barcode_id ||
                        barcodeInfo?.barcode_id?.length) &&
                      (typeof barcodeInfo?.barcode_id === 'string' ||
                        typeof barcodeInfo?.barcode_id === 'number')
                        ? barcodeInfo?.barcode_id
                        : barcodeInfo?.barcode_id?.slice(-1)
                    }
                  />
                  <CustomButton
                    buttonText={'Print Without UPC Scan'}
                    className="upc-scan-btn"
                    isActive
                    onClick={() => {
                      selectedVendor?.ID === 115 // MACY RS Vendor
                        ? setMacyVendorPopup(true)
                        : printNoUPCbuttonEvent()
                    }}
                    // onClick={() => {
                    //   printNoUPCbuttonEvent()
                    // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
