import { Button, SelectPicker } from 'rsuite'
import './style.scss'
import ccmLogo from '@img/CCM-logo.svg'
import Input from 'rsuite/Input'
import useLoginEnhancer from './useEnhancer'
import packageJSON from '@root/package.json'
import { setLoginInfo } from '@store/Slices/persistedDataSlice'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import Loader from '@components/Loader'

export default function Login({ t }: any) {
  const {
    getAuthToken,
    showInputs,
    warehouseData,
    dispatch,
    formData,
    setFormData,
    navigate,
    personData,
    loginInfo,
    errors,
    setErrors,
  } = useLoginEnhancer(t)

  const loginButtonRef = useRef<HTMLButtonElement>(null)

  const handleLoginForm = () => {
    if (!formData.badgeID && !personData.personId) {
      setErrors({
        ...errors,
        badgeID: t('scan_failed_error'),
      })
    } else if (!formData.selectedWarehouse && showInputs.warehouse) {
      setErrors({
        ...errors,
        selectedWarehouse: t('warehouse_error'),
      })
    } else {
      !personData.personId && getAuthToken(formData.badgeID)
      if (
        (formData.badgeID && formData.selectedWarehouse) ||
        personData.personId
      ) {
        dispatch(setLoginInfo(formData))
        !showInputs.badgeID && navigate('dashboard')
      }
    }
  }

  const loader = useSelector((store: any) => store.utility.loader)

  return (
    <>
      {loader && <Loader />}
      <div className="wrapper">
        <div className="mid_content">
          <div className="img_headline">
            <img src={ccmLogo} className="ccmlogo" />
            <div className="border_line"></div>
            <p className="headline">{t('app_slogan')}</p>
          </div>
          <div className="login_form_wrapper">
            <div
              className={`login_form ${
                !showInputs.badgeID || !showInputs.warehouse
                  ? 'single_input'
                  : ''
              }`}
            >
              {showInputs.badgeID && (
                <div className={errors.badgeID ? 'badge_error_msg' : ''}>
                  <div
                    className={
                      !showInputs.warehouse
                        ? 'section_m30_badge'
                        : 'badge_section'
                    }
                  >
                    <label
                      className={`label ${
                        errors.badgeID ? 'badge_error_title' : ''
                      }`}
                    >
                      {t('scan_badge_label')}
                    </label>
                    <Input
                      className="base_input"
                      id="login_input"
                      type="number"
                      onKeyDown={(event) => {
                        if (loader && formData.badgeID) {
                          return
                        }

                        if (event.key === 'Enter') {
                          loginButtonRef.current?.click()
                        }
                      }}
                      value={formData?.badgeID}
                      onChange={(value) => {
                        if (loader && formData.badgeID) {
                          return
                        }
                        errors.badgeID &&
                          setErrors({
                            ...errors,
                            badgeID: '',
                          })
                        setFormData({ ...formData, badgeID: value })
                      }}
                    />

                    {errors.badgeID && (
                      <div className="scan_failed">{errors.badgeID}</div>
                    )}
                  </div>
                </div>
              )}
              {showInputs.warehouse && (
                <div
                  className={errors.selectedWarehouse ? 'badge_error_msg' : ''}
                >
                  <div className="section_m30">
                    <label
                      className={`label whse_label ${
                        errors.selectedWarehouse ? 'badge_error_title' : ''
                      }`}
                    >
                      {t('warehouse_label')}
                    </label>
                    <SelectPicker
                      data={warehouseData?.map((item: any) => ({
                        label: item,
                        value: item,
                      }))}
                      placeholder={t('warehouse_plh')}
                      className="warehouse_dropdown"
                      searchable={false}
                      menuMaxHeight={200}
                      cleanable={false}
                      onChange={(value) => {
                        errors.selectedWarehouse &&
                          setErrors({
                            ...errors,
                            selectedWarehouse: '',
                          })
                        setFormData({ ...formData, selectedWarehouse: value })
                      }}
                      defaultValue={
                        loginInfo?.selectedWarehouse === ''
                          ? ''
                          : loginInfo.selectedWarehouse
                      }
                    />
                    {errors.selectedWarehouse && (
                      <div className="warehouse-error">
                        {errors.selectedWarehouse}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="login_btn_wrapper">
                <Button
                  ref={loginButtonRef}
                  className="submit_login"
                  type="submit"
                  onClick={handleLoginForm}
                >
                  {showInputs.badgeID && !showInputs.warehouse
                    ? t('next')
                    : t('login')}
                </Button>
              </div>
            </div>
          </div>
          <div className="bottom_info">
            <div className="station_name info">
              {t('station_id')} : FB-SWPL-28
            </div>
            <div className="spacer"></div>
            <div className="version_details info">
              {t('version')} : {packageJSON.version}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
