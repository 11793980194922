import Breadcrumb from '@components/Breadcrumb'
import './style.scss'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '@components/Loader'
import CustomPopup from '@root/src/components/CustomPopup'
import { useState } from 'react'
import { setNotification } from '@store/Slices/utilitySlice'
import { useTranslation } from 'react-i18next'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'

export default function Rugs({
  RUGS,
  selectedVendor,
  selectedRugsCat,
  setSelectedRugsCat,
  showPopup,
  setShowPopup,
  t,
  addDamageItem,
}: any) {
  const loader = useSelector((store: any) => store.utility.loader)
  const { getBarcodeData } = useClothingEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo } = useSelector((store: any) => store.pricing)
  const { barcodeInfo } = useSelector((store: any) => store.pricing)
  const [qnt, setQnt] = useState<string>('1')
  const dispatch = useDispatch()
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  function getBarcodeDetailsAndPrint(selectedItem: any) {
    getBarcodeData(
      105,
      parseInt(qnt),
      RUGS?.header?.sub_cat[0]?.stock_2_cat_id,
      '',
      '' + personData?.personName + '' + personData?.lastName,
      personData?.personId,
      palletInfo?.RawPalletId,
      'rugs',
      '' + selectedItem?.cat_id,
      'Rugs',
      selectedItem.description.join(' ')
    )
  }

  const { i18n } = useTranslation()

  return (
    <div className="rug-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-30'),
                  variant: 'warning',
                })
              )
              return
            }
            setShowPopup(false)
            getBarcodeDetailsAndPrint(selectedRugsCat)
            setQnt('1')
          }}
          cancelButtonAction={(e: any) => {
            setShowPopup(false)
          }}
        />
      )}

      <div>
        <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Rugs']} />
        <div className="rugs-content">
          <span className="rug-title">{t('Rug Sizes')}</span>
          <div className="rugs-sizes">
            {RUGS?.category?.map((item: any) => (
              <div
                className={
                  item === selectedRugsCat
                    ? 'size-container-selected'
                    : 'size-container'
                }
                key={item?.cat_id}
                onClick={() => {
                  setSelectedRugsCat(item)
                  setShowPopup(true)
                }}
              >
                {i18n.language === 'en' ||
                !RUGS?.translation[item?.description[0]]
                  ? item?.description[0]
                  : RUGS?.translation[item?.description[0]]}
              </div>
            ))}
          </div>
        </div>
      </div>

      {RUGS?.category?.length && <hr className="clothing-hr" />}

      {RUGS?.category?.length ? (
        <InfoSidebarPricing
          stockName={RUGS?.header?.main_cat?.stock_name}
          subCat={RUGS?.header?.sub_cat[0]?.sub_cat_name}
          mainCat={selectedSubCategory}
          stock2Cat={RUGS?.header?.sub_cat[0]?.stock_2_cat_id}
          Stock={RUGS?.header?.main_cat?.stock_number}
          price={barcodeInfo?.price || ''}
          hidePriceRange={true}
          addDamageItem={addDamageItem}
        />
      ) : (
        ''
      )}
    </div>
  )
}
