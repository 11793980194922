import { createSlice } from '@reduxjs/toolkit'
import type {
  personDataInterface,
  stateInterface,
} from '../../types/person_types'

const personDataSlice = createSlice({
  name: 'personData',
  initialState: {
    personData: {
      personName: '',
      lastName: '',
      middleName: '',
      personId: '',
    },
  },
  reducers: {
    setPersonData: (state: stateInterface, action: personDataInterface) => {
      state.personData = action.payload
    },
    removePersonData: (state: stateInterface) => {
      state.personData = {
        personName: '',
        lastName: '',
        middleName: '',
        personId: '',
      }
    },
  },
})

export const { setPersonData, removePersonData } = personDataSlice.actions
export default personDataSlice.reducer
