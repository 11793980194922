import CustomDropdown from '@components/Dropdown'
import './style.scss'
import { useEffect, useState } from 'react'
import CustomButton from '@components/CustomButton'
import { setNotification } from '@store/Slices/utilitySlice'
import {
  setPrinterStatus,
  setLastSelectedPrinter,
} from '@store/Slices/dashboard'

import { useDispatch, useSelector } from 'react-redux'
import {
  setPrintZplData,
  setSelectedPrinter,
} from '@store/Slices/persistedDataSlice'
import { useTranslation } from 'react-i18next'
export default function PrinterConfig(props: any) {
  const data = [].map((item) => ({
    label: item,
    value: item,
  }))
  const { lastSelectedPrinter } = useSelector((store: any) => store.dashboard)
  const [printerList, setPrinterList] = useState([])
  const [printListData, setPrintListData] = useState<any>(data)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { printZplString, selectedPrinter } = useSelector(
    (store: any) => store.persistedData
  )

  useEffect(() => {
    updatePrinterListData()
  }, [printerList])
  useEffect(() => {
    if (lastSelectedPrinter === '') {
      setPrinterList([])
      setPrintListData([])
    }
  }, [lastSelectedPrinter])

  useEffect(() => {
    dispatch(
      setPrinterStatus({
        isPrinterAvailable: false,
      })
    )
    updatePrinterListData()
    getPrintersFromBrowserPrint()
    getSelectedPrinterFromList(lastSelectedPrinter)
  }, [])

  function updatePrinterListData() {
    if ((printerList || []).length !== 0) {
      const data = printerList.map(({ name, uid }) => ({
        label: name,
        value: uid,
      }))
      setPrintListData(data)

      if (lastSelectedPrinter !== '') {
        getSelectedPrinterFromList(lastSelectedPrinter)
      }
    } else {
      setPrintListData([])
    }
  }
  const getPrintersFromBrowserPrint = () => {
    const browserPrint = (window as any).BrowserPrint

    browserPrint.getLocalDevices(
      function (deviceList: any) {
        setPrinterList(deviceList.printer)

        updatePrinterListData()
      },
      (err: any) => {
        console.log(err)
      }
    )
  }

  const printButtonAction = () => {
    if (
      selectedPrinter === undefined ||
      selectedPrinter === null ||
      selectedPrinter === ''
    ) {
      updatePrinterListData()
      dispatch(
        setNotification({
          notification: t('Printer is not connected'),
          variant: 'warning',
        })
      )

      dispatch(
        setPrinterStatus({
          isPrinterAvailable: false,
        })
      )
    } else {
      const networkCallResponse = '^XA^FO200,200^A0N36,36^FDTest Label^FS^XZ'
      dispatch(
        setPrintZplData({
          printZplString: !printZplString,
          printValues: networkCallResponse,
          showNotification: true,
        })
      )

      dispatch(setPrinterStatus({ isPrinterAvailable: true }))
    }
  }
  function getSelectedPrinterFromList(value: string) {
    const selectedPrin = printerList?.filter((item: any) => item.uid === value)

    if (selectedPrin?.length !== 0) {
      dispatch(setPrinterStatus({ isPrinterAvailable: true }))
      dispatch(
        setLastSelectedPrinter({
          lastSelectedPrinter: value,
        })
      )
      dispatch(
        setSelectedPrinter({
          selectedPrinter: selectedPrin[0],
        })
      )
    }
  }
  return (
    <>
      <div className="printerlist_container">
        <div className="printerlist-wrapper">
          <div className="main-area">
            <label className="printer-label-title">Printer Setting</label>
            {/* TEAM NUMBER SECTION */}
            <div className="list-number-row">
              <label className="list-label">Select printer</label>
              <CustomDropdown
                data={printListData}
                style={{ width: 244 }}
                searchable={false}
                menuMaxHeight={200}
                cleanable={false}
                onChange={(value: any) => {
                  getSelectedPrinterFromList(value)
                }}
                value={lastSelectedPrinter}
              />

              {/* <div className="getprinter" onClick={getPrintersFromBrowserPrint}>
                <CustomButton
                  buttonText={'Get Printers'}
                  isActive
                  className="pallet-scan-btn"
                />
              </div> */}

              <div className="getprinter" onClick={printButtonAction}>
                <CustomButton
                  buttonText={'Test'}
                  isActive
                  className="pallet-scan-btn"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
