import { useState } from 'react'
import Header from './components/Header/header'
import Vendors from './components/Vendors/vendors'
import PrinterConfig from './components/PrinterConfig/printerConfig'
import './style.scss'
import useDashboardEnhancer from './useEnhancer'
import Sidebar from './components/Sidebar/sidebar'
import GeneralInfo from '@components/GeneralInfo'
import { useSelector } from 'react-redux'
import Loader from '@components/Loader'

export default function Dashboard({
  t,
  isButtonDisabled,
  setIsButtonDisabled,
}: any) {
  const { vendorList, frequentSearchVendors, vendorListOriginal } =
    useDashboardEnhancer()
  const [searchKeywords, setSearchKeyword] = useState('')
  const { selectedSlideMenu } = useSelector((store: any) => store.dashboard)
  const loader = useSelector((store: any) => store.utility.loader)

  return (
    <div className="dashboard_wrapper">
      {loader && <Loader />}
      <div className="sidebar_parant">
        <Sidebar t={t} />
      </div>
      <div className="vendor_header">
        <Header
          {...{
            searchKeywords,
            setSearchKeyword,
            t,
          }}
          showSearchInput
          showPrinterLogo
          showFullScreenIcon
          showNotificationIcon
        />
        {selectedSlideMenu === 'dashboard' ? (
          <Vendors
            {...{
              searchKeywords,
              t,
              vendorList,
              frequentSearchVendors,
              vendorListOriginal,
            }}
          />
        ) : (
          <PrinterConfig />
        )}

        <GeneralInfo
          showProductiveUI
          isDashboard
          {...{ isButtonDisabled, setIsButtonDisabled }}
        />
      </div>
    </div>
  )
}
