import { useTranslation } from 'react-i18next'
import './style.scss'

export default function Card({
  info,
  imgURI,
  imgAlt,
  onClick,
  selectedCard,
  translations,
  style,
  imgWidth,
}: any) {
  const { i18n } = useTranslation()
  function capitalizeAfterDot(inputString: string) {
    const itemStringData =
      inputString.trim().charAt(0).toUpperCase() +
      inputString.trim().slice(1).toLowerCase()

    return itemStringData.replace(
      /(\.\s*)(.)/g,
      (match, dots, letter) => dots + letter.toUpperCase()
    )
  }

  return (
    <div
      className={selectedCard ? 'custom-card-selected' : 'custom-card'}
      onClick={onClick}
      style={style}
    >
      <img src={imgURI} alt={imgAlt} width={imgWidth ?? 50} />
      <ul>
        {info.map((item: string) => (
          <li key={item}>
            {i18n.language === 'en' ||
            !translations ||
            !translations[item.trim()]
              ? capitalizeAfterDot(item)
              : translations[item.trim()].charAt(0).toUpperCase() +
                translations[item.trim()].slice(1).toLowerCase()}
          </li>
        ))}
      </ul>
    </div>
  )
}
