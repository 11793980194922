import TabButton from '@components/TabButton'
import './style.scss'

interface tab {
  tab: string
  hide: boolean
  clickable: boolean
}

interface TabSwitcherPropTypes {
  tabs: tab[]
  activeTab: string
  setActiveTab: any
}

export default function TabSwitcher({
  tabs,
  activeTab,
  setActiveTab,
}: TabSwitcherPropTypes) {
  return (
    <div className="tabs-switcher">
      {tabs
        .filter((item) => !item.hide)
        .map((item: tab) => (
          <div
            onClick={() => item.clickable && setActiveTab(item.tab)}
            key={item.tab}
          >
            <TabButton
              buttonText={item.tab}
              isActive={item.tab === activeTab}
              className="individual-tab"
            />
          </div>
        ))}
    </div>
  )
}
