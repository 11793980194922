export default {
  getJWT: '/token',
  getVendorList: '/vendor/list',
  getTeamNumber: (warehouse: string) =>
    `teamNumber/list?wareHouse=${warehouse}`,
  scanTemplate: (palletId: number) => `/pallet/${palletId}/status`,
  looseDomesticAccessories: '/looseDomesticAccessories',
  validatePallate: (
    palletID: number,
    userName: string,
    teamNumber: number,
    vendorId: number
  ) =>
    `/pallet/${palletID}/status?userName=${userName}&teamNumber=${teamNumber}&vendorId=${vendorId}`,
  userLogin: (badgeNumber: number, userLogin: string) =>
    `/user/login?badgeNumber=${badgeNumber}&userLogin=${userLogin}`,
  warehouse: '/warehouse',
  gettoteLabel: (palletID: number, stockId: number, stockName: string) =>
    `/toteLabel/${palletID}?stockId=${stockId}&stockName=${stockName}`,
  getUPCDetails: (
    vendorID: number,
    upcID: number,
    userName: string,
    scannerID: number | string,
    personID: number,
    stock2catID: number,
    palletID: number,
    badgeNumber: number
  ) =>
    `/pricing/details?vendorId=${vendorID}&upcId=${upcID}&userName=${userName}&scannerId=${scannerID}&personId=${personID}&stock2CatId=${stock2catID}&palletId=${palletID}&badgeNumber=${badgeNumber}`,

  getPricingData: (
    type: string,
    stockName: string,
    teamNumber: number,
    letteredCLothing: string
  ) =>
    `/dashBoard?dashBoardType=${type}&stockName=${stockName}&teamNumber=${teamNumber}${letteredCLothing}`,
  getBarcodeDetails: '/dashBoard/barCode/print',
  damageItem: (barcodeId: number) =>
    `/damage/item?barcodeId=${barcodeId}&action=damage`,
  getUPCDetailsForNoUPC: (
    vendorID: number,
    upcID: string,
    userName: string,
    scannerID: number | string,
    personID: number,
    stock2catID: number,
    palletID: number,
    price: number,
    description: string,
    qty: number,
    badgeNumber: number
  ) =>
    `/pricing/details?vendorId=${vendorID}&upcId=${upcID}&userName=${userName}&scannerId=${scannerID}&personId=${personID}&stock2CatId=${stock2catID}&palletId=${palletID}&description=${description}&price=${price}&qty=${qty}&badgeNumber=${badgeNumber}`,

  getcategory: () => '/category',
  updateProductivityCount: (badgeNumber: number, barcodeCount: number) =>
    `/productivity/count/${badgeNumber}?barcodeCount=${barcodeCount}`,
  getStandardPriceForUPC: (
    mainCategory: number,
    subCategory: string,
    vendorID: number,
    scannerID: number | string,
    personID: number,
    stock2catID: number,
    palletID: number,
    qty: number,
    badgeNumber: number
  ) =>
    `/pricing/details?mainCategory=${mainCategory}&subCategory=${subCategory}&vendorId=${vendorID}&personId=${personID}&scannerId=${scannerID}&stock2CatId=${stock2catID}&palletId=${palletID}&qty=${qty}&badgeNumber=${badgeNumber}`,
}
