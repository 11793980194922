import api_endpoints from '@services/api_endpoints'
import { getRequest, setAuthorizationToken } from '@services/axios'
import publicAxios from '@services/publicAxios'
import {
  removeWarehouseData,
  setUserProductivityInfo,
  setWarehouseData,
} from '@store/Slices/persistedDataSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setLoader, setNotification } from '@store/Slices/utilitySlice'
import { useNavigate } from 'react-router-dom'
import type { authResponseInterface, loginForm } from '../../types/auth'
import { removePersonData, setPersonData } from '@store/Slices/personData'
import { getRequestIdentifier } from '@helpers/index'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'

export default function useLoginEnhancer(t: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { warehouseData, loginInfo } = useSelector(
    (store: any) => store.persistedData
  )
  const { personData } = useSelector((store: any) => store.personData)
  const [showInputs, setInputs] = useState({ warehouse: false, badgeID: true })
  const [formData, setFormData] = useState<loginForm>({
    badgeID: '',
    selectedWarehouse: loginInfo.selectedWarehouse || '',
  })
  const [errors, setErrors] = useState<loginForm>({
    badgeID: '',
    selectedWarehouse: '',
  })
  const { updateProductivityCount } = useClothingEnhancer()

  useEffect(() => {
    // Check if personData.personId is available and navigate to the dashboard if it exists
    if (personData?.personId && loginInfo.selectedWarehouse) {
      navigate('/dashboard')
    }

    // Check if warehouseData is available and update the showInputs state accordingly
    if (
      warehouseData.length &&
      !loginInfo.selectedWarehouse &&
      personData.personId
    ) {
      setInputs({ warehouse: true, badgeID: false })
    } else if (warehouseData.length && !loginInfo.selectedWarehouse) {
      dispatch(removePersonData())
      dispatch(removeWarehouseData())
    } else if (warehouseData.length) {
      setInputs({ warehouse: true, badgeID: true })
    }
  }, [])

  const getAuthToken = async (number: any) => {
    dispatch(setLoader(true))

    const headers = {
      client_id: process.env.REACT_APP_ACCESS_TOKEN,
      client_secret: process.env.REACT_APP_SECRET_TOKEN,
      scope: 'READ',
      client_name: 'ReadyStore',
      grant_type: 'CLIENT_CREDENTIALS',
    }

    try {
      // Make an API request to fetch the authentication token
      const response: any = await publicAxios.post(
        process.env.REACT_APP_OAUTH_ENDPOINT + api_endpoints.getJWT,
        {},
        { headers }
      )

      // Call the userLogin function with the provided number and authentication response
      userLogin(number, response).then((userData) => {
        dispatch(setLoader(false))

        // Check if warehouseData is not available
        // if (!warehouseData.length) {
        if (userData?.success) {
          getWarehouseList()
        }
        // }
        return userData
      })
    } catch (error) {
      console.log('CATCH', error)

      // Temporarily using a hardcoded token for testing purposes
      // Call the userLogin function with the provided number and authentication response
      // userLogin(number, {
      //   access_token:
      //     'iBKwJWQ8ksmr3R4FH0fw53H9nUD8WrY7YBfvw6xlaRlzoIL9keovXc9t9DdcC5bwfl0M_J1UR-ScYAkT_9QqNQ',
      //   scope: 'READ',
      //   token_type: 'Bearer',
      //   expires_in: 86400,
      // }).then((userData) => {
      //   dispatch(setLoader(false))

      //   // Check if warehouseData is not available
      //   if (!warehouseData.length) {
      //     getWarehouseList()
      //   }
      //   return userData
      // })
    }
  }

  const userLogin = async (
    number: any,
    authResponse: authResponseInterface
  ) => {
    dispatch(setLoader(true))

    if (!number) {
      return
    }

    // Make an API request to perform the user login
    const loginResponse: any = await getRequest(
      api_endpoints.userLogin(number, 'Y'),
      {
        headers: {
          transaction_type: 'pricing_app',
          Authorization: 'Bearer ' + authResponse.access_token,
        },
      }
    )

    if (loginResponse?.success && loginResponse?.data?.length) {
      dispatch(setLoader(false))

      if (loginResponse?.data?.length) {
        setAuthorizationToken(authResponse.access_token)

        // Check if a warehouse is selected and navigate to the dashboard
        loginInfo.selectedWarehouse && navigate('dashboard')

        // Update the personData state with the user data
        dispatch(setUserProductivityInfo(loginResponse?.data[0]))

        dispatch(
          setPersonData({
            ...personData,
            personName: loginResponse?.data[0].first_name,
            lastName: loginResponse?.data[0].last_name,
            middleName: loginResponse?.data[0].middle_name,
            personId: loginResponse?.data[0].person_id,
          })
        )
        updateProductivityCount(0, number)
      }
    } else {
      dispatch(setLoader(false))
      if (!loginResponse?.message?.includes('already logged in')) {
        setErrors({
          ...errors,
          badgeID: t('invalid_badge_error'),
        })
      } else {
        dispatch(
          setNotification({
            notification: t(loginResponse?.message),
            variant: 'error',
          })
        )
      }

      // Update the errors state with an invalid badge id message
    }
    return loginResponse
  }

  const getWarehouseList = async () => {
    dispatch(setLoader(true))

    // Make an API request to fetch the warehouse list
    const response: any = await getRequest(api_endpoints.warehouse, {
      headers: {
        transaction_type: getRequestIdentifier(
          'Warehouse',
          personData?.personId,
          '',
          ''
        ),
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
      },
    })

    if (response.success) {
      dispatch(setLoader(false))
      setInputs({ warehouse: true, badgeID: false })
      // Update the warehouseData state with the fetched data
      dispatch(setWarehouseData(response?.data))
    } else {
      dispatch(setLoader(false))
      // TODO : set error here
    }
  }

  return {
    getAuthToken,
    showInputs,
    setInputs,
    warehouseData,
    navigate,
    dispatch,
    personData,
    setFormData,
    formData,
    loginInfo,
    errors,
    setErrors,
  }
}
