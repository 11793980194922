import { setNotification } from '@store/Slices/utilitySlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Notification, Placeholder, useToaster } from 'rsuite'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { updateRecentNotifications } from '@store/Slices/dashboard'
import { getUnicode } from '@helpers/index'

const CustomNotification = () => {
  const toaster = useToaster()
  const dispatch = useDispatch()
  const { i18n } = useTranslation()
  const { notification, variant, translation } = useSelector(
    (store: any) => store.utility
  )

  const showNotification = () => {
    toaster.push(
      <Notification
        type={variant ?? 'info'}
        header={
          i18n.language === 'es' && translation ? translation : notification
        }
        closable
      >
        <Placeholder.Paragraph style={{ width: 500 }} rows={0} />
      </Notification>,
      {
        placement: 'topEnd',
        duration: 5000,
      }
    )
    dispatch(
      setNotification({
        notification: '',
        variant: '',
        translation: '',
      })
    )
    const notificationToStore: any = {
      id: getUnicode(),
      notification,
      variant,
    }
    dispatch(updateRecentNotifications(notificationToStore))
  }

  useEffect(() => {
    notification && showNotification()
  }, [notification])

  return <></>
}

export default CustomNotification
