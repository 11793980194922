import CustomInput from '@components/Input'
import './style.scss'
import { InputGroup } from 'rsuite'
import CustomButton from '@components/CustomButton'
import { useDispatch, useSelector } from 'react-redux'
import InputScannerImg from '@img/input_scanner_dark.svg'
import { removeLetterClothingUPCInfo } from '@store/Slices/pricing'
import { useEffect, useState } from 'react'

export default function InfoSidebarPricing({
  stockName,
  subCat,
  mainCat,
  stock2Cat,
  Stock,
  priceRange,
  hidePriceRange,
  addDamageItem,
  letterClothing,
  getItemDetails,
  disabledUPCScan,
}: any) {
  const dispatch = useDispatch()

  const { barcodeInfo, LetterClothingUPCInfo } = useSelector(
    (store: any) => store.pricing
  )
  const loader = useSelector((store: any) => store.utility.loader)

  const [upcNumber, setUpcNumber] = useState<string>('')

  useEffect(() => {
    setUpcNumber(LetterClothingUPCInfo?.upc)
  }, [])
  useEffect(() => {
    const inputupc = document.getElementById('UPCInput')
    inputupc?.focus()
  }, [LetterClothingUPCInfo])

  return (
    <div
      className={
        letterClothing
          ? 'info-box-category letterClothingSidebar'
          : 'info-box-category'
      }
    >
      {letterClothing && (
        <>
          <div className="input-container">
            <label>Scan UPC</label>
            {/* <CustomInput
              width={'200px'}
              style={{ border: '1px solid #000000' }}
              textColor={'#000'}
              value={priceRange}
            /> */}
            <InputGroup inside style={{ width: '200px' }}>
              <CustomInput
                width={'200px'}
                id="UPCInput"
                step={1}
                style={{ border: '1px solid #000000' }}
                disabled={disabledUPCScan}
                value={upcNumber}
                onChange={(e: string) => {
                  setUpcNumber(e)
                }}
                onKeyPress={(event: any) => {
                  if (event.key === '~') {
                    event.preventDefault()
                  }
                }}
                onPaste={(event: any) => {
                  const pastedValue = event.clipboardData.getData('text')

                  if (pastedValue.includes('~')) {
                    event.preventDefault()
                  }
                }}
                onKeyDown={(event: any) => {
                  if (loader) {
                    return
                  }

                  if (event.keyCode === 13 || event.key === 'Enter') {
                    dispatch(removeLetterClothingUPCInfo())
                    getItemDetails(event.target.value)
                    const inputElement = document.getElementById('UPCInput')
                    if (inputElement) {
                      inputElement.blur()
                    }
                  }
                }}
                onFocus={() => {
                  setUpcNumber('')
                  if (LetterClothingUPCInfo?.price) {
                    setUpcNumber('')
                    // dispatch(removeLetterClothingUPCInfo())
                  }
                }}
              />
              <InputGroup.Addon>
                <img src={InputScannerImg} className="input_icon" />
              </InputGroup.Addon>
            </InputGroup>
          </div>
          <div className="input-container">
            <label>Description</label>
            <CustomInput
              width={'200px'}
              as="textarea"
              style={{ border: '1px solid #000000', height: '69px' }}
              textColor={'#000'}
              value={LetterClothingUPCInfo?.description ?? ''}
              disabled
            />
          </div>
        </>
      )}

      {!hidePriceRange && (
        <div className="input-container">
          <label>Price range</label>
          <CustomInput
            width={'200px'}
            style={{ border: '3px solid #000000' }}
            textColor={'#000'}
            value={priceRange}
            disabled
          />
        </div>
      )}
      <div className="input-container">
        <label>Price</label>
        <InputGroup inside style={{ width: '200px' }}>
          <InputGroup.Addon style={{ fontSize: 22, paddingTop: 13 }}>
            $
          </InputGroup.Addon>
          <CustomInput
            width={'200px'}
            style={{ border: '3px solid #000000', paddingLeft: '24px' }}
            textColor={'#000'}
            value={
              letterClothing
                ? LetterClothingUPCInfo?.price
                  ? LetterClothingUPCInfo?.price.toFixed(2)
                  : ''
                : barcodeInfo?.price
                ? Number(barcodeInfo?.price).toFixed(2)
                : ''
            }
            disabled
          />
        </InputGroup>
      </div>

      <hr />

      <div className="info-container">
        <div className="field-info">
          <div className="field-label-info">Stock Name</div>
          <div className="field-value-info">{stockName}</div>
        </div>
        <div className="field-info">
          <div className="field-label-info">Main Cat</div>
          <div className="field-value-info">{mainCat}</div>
        </div>
        <div className="field-info">
          <div className="field-label-info">Sub Cat</div>
          <div className="field-value-info">
            {subCat?.slice(0, 1) + subCat?.slice(1).toLowerCase()}
          </div>
        </div>
        <div className="field-info">
          <div className="field-label-info">Stock</div>
          <div className="field-value-info">{Stock}</div>
        </div>
        <div className="field-info">
          <div className="field-label-info">Stock 2 Cat #</div>
          <div className="field-value-info">{stock2Cat}</div>
        </div>
      </div>

      <div className="info-section-buttons">
        <CustomButton
          buttonText="Damage Item"
          isActive={('' + (barcodeInfo?.barcode_id ?? '')).length !== 0}
          onClick={addDamageItem}
        />
        {/* <CustomButton
          buttonText="Enter Retail $$$"
          activeColor={'#39C769'}
          activeTextColor={'#fff'}
          darkerText={false}
          className="retail-btn"
        /> */}
      </div>
    </div>
  )
}
