import './style.scss'
import CustomButton from '@components/CustomButton'
import { InputGroup } from 'rsuite'
import Close from '@img/close.svg'
import { useSelector } from 'react-redux'

const MacyPopup = ({
  headline,
  cancelButtonAction,
  onCategorySelection,
}: any) => {
  const { selectedMainCategory } = useSelector((store: any) => store.dashboard)
  const MacyBtns = selectedMainCategory.includes('Clothing')
    ? [
        { title: 'Adult Clothing', price: 30 },
        { title: 'Kids Clothing', price: 20 },
      ]
    : selectedMainCategory.includes('Footwear')
    ? [
        { title: 'Adult Footwear', price: 40 },
        { title: 'Child Footwear', price: 20 },
      ]
    : [{ title: 'Misc CAT', price: 20 }]

  return (
    <div className="loader-container">
      <div className="popup-layer macy-popup">
        <div className="popup-header">
          <p className="popup-headline">{headline}</p>
          <InputGroup.Addon
            style={{ backgroundColor: 'transparent', border: '0px' }}
          >
            <img
              src={Close}
              className="input_icon"
              onClick={cancelButtonAction}
            />
          </InputGroup.Addon>
        </div>

        <div className="sub-headline-macy">Select Category</div>

        <div className="">
          {MacyBtns.map((btn: any) => (
            <CustomButton
              onClick={() => onCategorySelection(btn)}
              styles={{
                marginBottom: '20px',
              }}
              btnStyles={{
                fontFamily: 'Inter',
                fontWeight: 500,
                fontSize: '18px',
              }}
              key={btn?.title}
              buttonText={btn?.title}
              width="250px"
              className="upc-scan-btn"
              isActive
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MacyPopup
