import PalletScan from '@tabs/PalletScan'
import Clothing from '@tabs/Clothing'
import './style.scss'
import UPCScan from '@tabs/UPCScan'
import TemplateDom from '@tabs/TemplateDom'
import TemplateAcc from '@tabs/TemplateAcc'
import Footwear from '@tabs/Footwear'
import Rugs from '@tabs/Rugs'
import Misc from '@tabs/Misc'

const ContentSwitcher = ({
  option,
  t,
  pricingData,
  setTabs,
  selectedVendor,
  personData,
  selectedClothingCat,
  setSelectedClothingCat,
  selectedFootwearCat,
  setSelectedFootwearCat,
  showPopup,
  setShowPopup,
  barcodeDesc,
  setBarcodeDesc,
  barcodeTitle,
  setBarcodeTitle,
  selectedRugsCat,
  setSelectedRugsCat,
  selectedDOMParentCard,
  setSelectedDOMParentCard,
  selectedDOMChildCard,
  setSelectedDOMChildCard,
  selectedTemplateAccCat,
  setSelectedTemplateAccCat,
  selectedMiscCat,
  setSelectedMiscCat,
  showNoUPCPopup,
  setShowNoUPCPopup,
  isRawPalletScanMode,
  setRawPalletMode,
  scanTemplateData,
  setScanTemplateData,
  addDamageItem,
  otherAmoutPrice,
  setOtherAmoutPrice,
  otherAmoutSelected,
  setotherAmoutSelected,
  showPriceEditPopup,
  setshowPriceEditPopup,
  isLetterClothing,
  selectedLetterClothingCat,
  setSelectedLetterClothingCat,
}: any) => {
  const { CLOTHING, FOOTWEAR, TEMPLATE_DOM, TEMPLATE_ACC, RUGS, MISC } =
    pricingData

  switch (option) {
    case 'Pallet Scan':
      return (
        <PalletScan
          {...{
            setTabs,
            t,
            selectedVendor,
            personData,
            isRawPalletScanMode,
            setRawPalletMode,
            scanTemplateData,
            setScanTemplateData,
          }}
        />
      )
    case 'Item/UPC Scan':
      return (
        <UPCScan
          {...{
            selectedVendor,
            t,
            showPopup,
            setShowPopup,
            addDamageItem,
            showPriceEditPopup,
            setshowPriceEditPopup,
            option,
          }}
        />
      )
    case 'Clothing':
      return (
        <Clothing
          {...{
            CLOTHING,
            selectedVendor,
            selectedClothingCat,
            setSelectedClothingCat,
            showPopup,
            setShowPopup,
            barcodeDesc,
            setBarcodeDesc,
            barcodeTitle,
            setBarcodeTitle,
            t,
            addDamageItem,
            option,
            isLetterClothing,
            selectedLetterClothingCat,
            setSelectedLetterClothingCat,
          }}
        />
      )
    case 'Template DOM':
      return (
        <TemplateDom
          {...{
            TEMPLATE_DOM,
            selectedVendor,
            selectedDOMParentCard,
            setSelectedDOMParentCard,
            selectedDOMChildCard,
            setSelectedDOMChildCard,
            showPopup,
            setShowPopup,
            t,
            addDamageItem,
          }}
        />
      )
    case 'Template ACC':
      return (
        <TemplateAcc
          {...{
            TEMPLATE_ACC,
            selectedVendor,
            selectedTemplateAccCat,
            setSelectedTemplateAccCat,
            showPopup,
            setShowPopup,
            t,
            addDamageItem,
          }}
        />
      )
    case 'Footwear':
      return (
        <Footwear
          {...{
            FOOTWEAR,
            selectedVendor,
            selectedFootwearCat,
            setSelectedFootwearCat,
            showPopup,
            setShowPopup,
            t,
            addDamageItem,
          }}
        />
      )
    case 'Rugs':
      return (
        <Rugs
          {...{
            RUGS,
            selectedVendor,
            selectedRugsCat,
            setSelectedRugsCat,
            showPopup,
            setShowPopup,
            t,
            addDamageItem,
          }}
        />
      )
    case 'Misc':
      return (
        <Misc
          {...{
            MISC,
            t,
            selectedVendor,
            selectedMiscCat,
            setSelectedMiscCat,
            showPopup,
            setShowPopup,
            showNoUPCPopup,
            setShowNoUPCPopup,
            addDamageItem,
            otherAmoutPrice,
            setOtherAmoutPrice,
            otherAmoutSelected,
            setotherAmoutSelected,
            option,
          }}
        />
      )
    default:
      return <PalletScan />
  }
}

export default ContentSwitcher
