import Header from '../Dashboard/components/Header/header'
import GeneralInfo from '@components/GeneralInfo'
import TabSwitcher from '@root/src/components/TabSwitcher'
import './style.scss'
import usePricingEnhancer from './usePricingEnhancer'
import ContentSwitcher from './ContentSwitcher'

export default function Pricing({
  t,
  isButtonDisabled,
  setIsButtonDisabled,
}: any) {
  const {
    setActiveTab,
    activeTab,
    tabs,
    pricingData,
    setTabs,
    selectedVendor,
    personData,
    selectedClothingCat,
    setSelectedClothingCat,
    selectedFootwearCat,
    setSelectedFootwearCat,
    showPopup,
    setShowPopup,
    barcodeDesc,
    setBarcodeDesc,
    barcodeTitle,
    setBarcodeTitle,
    selectedRugsCat,
    setSelectedRugsCat,
    selectedDOMParentCard,
    setSelectedDOMParentCard,
    selectedDOMChildCard,
    setSelectedDOMChildCard,
    selectedTemplateAccCat,
    setSelectedTemplateAccCat,
    selectedMiscCat,
    setSelectedMiscCat,
    showNoUPCPopup,
    setShowNoUPCPopup,
    isRawPalletScanMode,
    setRawPalletMode,
    scanTemplateData,
    setScanTemplateData,
    addDamageItem,
    otherAmoutPrice,
    setOtherAmoutPrice,
    otherAmoutSelected,
    setotherAmoutSelected,
    showPriceEditPopup,
    setshowPriceEditPopup,
    isLetterClothing,
    selectedLetterClothingCat,
    setSelectedLetterClothingCat,
  } = usePricingEnhancer()

  return (
    <>
      <div className="pricing-container">
        <Header
          t={t}
          showExit
          showLogo
          showPrinterLogo
          showFullScreenIcon
          showNotificationIcon
        />
        <TabSwitcher
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <div className="content-switcher-wrapper">
          <ContentSwitcher
            option={activeTab}
            {...{
              t,
              pricingData,
              setTabs,
              selectedVendor,
              personData,
              selectedClothingCat,
              setSelectedClothingCat,
              selectedFootwearCat,
              setSelectedFootwearCat,
              showPopup,
              setShowPopup,
              barcodeDesc,
              setBarcodeDesc,
              barcodeTitle,
              setBarcodeTitle,
              selectedRugsCat,
              setSelectedRugsCat,
              selectedDOMParentCard,
              setSelectedDOMParentCard,
              selectedDOMChildCard,
              setSelectedDOMChildCard,
              selectedTemplateAccCat,
              setSelectedTemplateAccCat,
              selectedMiscCat,
              setSelectedMiscCat,
              showNoUPCPopup,
              setShowNoUPCPopup,
              isRawPalletScanMode,
              setRawPalletMode,
              scanTemplateData,
              setScanTemplateData,
              addDamageItem,
              otherAmoutPrice,
              setOtherAmoutPrice,
              otherAmoutSelected,
              setotherAmoutSelected,
              showPriceEditPopup,
              setshowPriceEditPopup,
              isLetterClothing,
              selectedLetterClothingCat,
              setSelectedLetterClothingCat,
            }}
          />
        </div>
      </div>
      <GeneralInfo
        showProductiveUI
        {...{ isButtonDisabled, setIsButtonDisabled }}
      />
    </>
  )
}
