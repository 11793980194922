import { SelectPicker } from 'rsuite'
import './style.scss'

// interface customDropdownPropTypes {
//   data: any
//   style?: object
//   className?: string
// }

export default function CustomDropdown(props: any) {
  const { data, style, isError, ...rest } = props
  return (
    <SelectPicker
      data={data}
      style={style}
      // className={`custom_dropdown ${className}`}
      className={`custom_dropdown ${isError ? 'dropdown-error' : ''}`}
      {...rest}
    />
  )
}
