import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import Babies from '@img/Footwear/Babies.svg'
import Boots from '@img/Footwear/Boots.svg'
import FlipFlop from '@img/Footwear/FlipFlop.svg'
import MenShoes from '@img/Footwear/MenShoes.svg'
import HighHeels from '@img/Footwear/High heels.svg'
import Sandals from '@img/Footwear/Sandals.svg'
import Slippers from '@img/Footwear/Slippers.svg'
import WinterBoots from '@img/Footwear/WinterBoots.svg'
import Card from '@root/src/components/Card'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import Loader from '@components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import CustomPopup from '@root/src/components/CustomPopup'
import { useState } from 'react'
import { setNotification } from '@store/Slices/utilitySlice'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'

export default function Footwear({
  FOOTWEAR,
  selectedVendor,
  selectedFootwearCat,
  setSelectedFootwearCat,
  showPopup,
  setShowPopup,
  t,
  addDamageItem,
}: any) {
  const { getBarcodeData } = useClothingEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo } = useSelector((store: any) => store.pricing)
  const { barcodeInfo } = useSelector((store: any) => store.pricing)

  const svgImgs = [
    HighHeels,
    Babies,
    MenShoes,
    FlipFlop,
    Boots,
    Slippers,
    Sandals,
    WinterBoots,
  ]

  const loader = useSelector((store: any) => store.utility.loader)
  const [qnt, setQnt] = useState<string>('1')
  const dispatch = useDispatch()
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  function getBarcodeDetailsAndPrint(selectedItem: any) {
    const descriptionA = selectedItem.description.join(' ')
    const description = selectedItem.description.join('/')

    let barcodeTitle = 'Footwear'
    let findVariable = false
    FOOTWEAR?.header?.sub_cat.forEach((item: any) => {
      if (
        descriptionA.includes('SANDALS') &&
        item.sub_cat_name.includes('SANDALS') &&
        !findVariable
      ) {
        findVariable = true
        barcodeTitle = 'Sandals'
      } else if (
        descriptionA.includes('WINTERBOOTS') &&
        item.sub_cat_name.includes('WINTER BOOTS') &&
        !findVariable
      ) {
        findVariable = true
        barcodeTitle = 'Winter Boots'
      } else if (
        descriptionA.includes('FLIP FLOPS') &&
        item.sub_cat_name.includes('FLIP FLOPS') &&
        !findVariable
      ) {
        findVariable = true
        barcodeTitle = 'Flip Flops'
      } else if (!findVariable) {
        barcodeTitle = 'Footwear'
      }
    })
    console.log('barcodeTitle', barcodeTitle)

    getBarcodeData(
      104,
      parseInt(qnt),
      FOOTWEAR?.header?.sub_cat[0]?.stock_2_cat_id,
      '',
      '' + personData?.personName + '' + personData?.lastName,
      personData?.personId,
      palletInfo?.RawPalletId,
      'footwear',
      '' + selectedItem?.cat_id,
      barcodeTitle,
      description
    )
  }

  return (
    <div className="footwear-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            console.log(e)
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-30'),
                  variant: 'warning',
                })
              )
              return
            }

            setShowPopup(false)
            getBarcodeDetailsAndPrint(selectedFootwearCat)
            setQnt('1')

            console.log(e)
          }}
          cancelButtonAction={(e: any) => {
            console.log(e)
            setShowPopup(false)
          }}
        />
      )}

      <div className="left-container">
        <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Footwear']} />
        {FOOTWEAR?.category?.length ? (
          <div className="footwear-cards">
            {/* ADULT SECTION */}
            <div className="adult-section">
              <p className="cat-title">{t('All Season')}</p>
              {FOOTWEAR?.category
                ?.slice(0, 4)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={FOOTWEAR?.translation}
                    imgURI={svgImgs[index]}
                    imgAlt={svgImgs[index]}
                    onClick={() => {
                      setSelectedFootwearCat(item)
                      setShowPopup(true)
                    }}
                    selectedCard={item === selectedFootwearCat}
                  />
                ))}
            </div>

            {/* CHILD SECTION */}
            <div className="child-section">
              <p className="cat-title">{t('All Season')}</p>
              {FOOTWEAR?.category
                ?.slice(4, 7)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={FOOTWEAR?.translation}
                    imgURI={svgImgs[index + 4]}
                    imgAlt={svgImgs[index + 4]}
                    onClick={() => {
                      setSelectedFootwearCat(item)
                      setShowPopup(true)
                    }}
                    selectedCard={item === selectedFootwearCat}
                  />
                ))}
            </div>

            {/* ADULT SWIMWEAR SECTION */}
            <div className="adult-swimwear-section">
              <p className="cat-title">{t('Winter')}</p>
              {FOOTWEAR?.category
                ?.slice(7, 8)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={FOOTWEAR?.translation}
                    imgURI={svgImgs[index + 7]}
                    imgAlt={svgImgs[index + 7]}
                    onClick={() => {
                      setSelectedFootwearCat(item)
                      setShowPopup(true)
                    }}
                    selectedCard={item === selectedFootwearCat}
                  />
                ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {FOOTWEAR?.category?.length && <hr className="clothing-hr" />}

      {FOOTWEAR?.category?.length ? (
        <InfoSidebarPricing
          stockName={FOOTWEAR?.header?.main_cat?.stock_name}
          subCat={FOOTWEAR?.header?.sub_cat[0]?.sub_cat_name}
          mainCat={selectedSubCategory}
          stock2Cat={FOOTWEAR?.header?.sub_cat[0]?.stock_2_cat_id}
          Stock={FOOTWEAR?.header?.main_cat?.stock_number}
          priceRange={selectedFootwearCat?.price_range}
          price={barcodeInfo?.price || ''}
          addDamageItem={addDamageItem}
        />
      ) : (
        ''
      )}
    </div>
  )
}
