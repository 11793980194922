import {
  removeAllNotifications,
  removeMainCategory,
  removeSelectedVendor,
  resetVendorList,
  setSelectedSideMenu,
  updateFullScreen,
} from '@store/Slices/dashboard'
import { removePersonData } from '@store/Slices/personData'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getRequest } from '@services/axios'
import api_endpoints from '@services/api_endpoints'
import { removeUserProductivityInfo } from '@store/Slices/persistedDataSlice'
import {
  removeLetterClothingUPCInfo,
  removePalletData,
  removeProcessPalletInfo,
  removeProductivitylastLoginTime,
  removeSelectedTeamNumber,
  removeUPCData,
} from '@store/Slices/pricing'

export default function useLogoutEnhancer() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loginInfo } = useSelector((store: any) => store.persistedData)

  async function logoutUser() {
    const basicAuth = sessionStorage.getItem('Authorization')

    if (!basicAuth) {
      clearUserData()
      return
    }
    const loginResponse: any = await getRequest(
      api_endpoints.userLogin(loginInfo.badgeID, 'N'),
      {
        headers: {
          transaction_type: 'pricing_app',
          Authorization: 'Bearer ' + sessionStorage.getItem('Authorization'),
        },
      }
    )
    console.log(loginResponse, 'loginResponse')

    if (loginResponse?.success) {
      dispatch(removeSelectedVendor())
      dispatch(removePalletData())
      dispatch(removeProcessPalletInfo())
      dispatch(removeUPCData())
      dispatch(removeLetterClothingUPCInfo())
      dispatch(removeMainCategory())
      dispatch(removeSelectedTeamNumber())
      dispatch(resetVendorList())

      dispatch(removeUserProductivityInfo())
      sessionStorage.removeItem('Authorization')
      dispatch(removePersonData())
      dispatch(
        setSelectedSideMenu({
          selectedSlideMenu: 'dashboard',
        })
      )
      navigate('/')
      if (document.fullscreenElement) {
        dispatch(updateFullScreen())
        document?.exitFullscreen()
      }
      dispatch(removeAllNotifications())
      dispatch(removeProductivitylastLoginTime())
    }
  }
  async function clearUserData() {
    dispatch(removeSelectedVendor())
    dispatch(removePalletData())
    dispatch(removeProcessPalletInfo())
    dispatch(removeUPCData())
    dispatch(removeLetterClothingUPCInfo())
    dispatch(removeMainCategory())
    dispatch(removeSelectedTeamNumber())

    dispatch(removeUserProductivityInfo())
    sessionStorage.removeItem('Authorization')
    dispatch(removePersonData())
    dispatch(
      setSelectedSideMenu({
        selectedSlideMenu: 'dashboard',
      })
    )
    navigate('/')
    if (document.fullscreenElement) {
      dispatch(updateFullScreen())
      document?.exitFullscreen()
    }
    dispatch(removeAllNotifications())
    dispatch(removeProductivitylastLoginTime())
  }
  return { logoutUser, clearUserData }
}
