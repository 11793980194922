import Spinner from '@img/spinner.gif'
import './style.scss'

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={Spinner} alt="Loading..." className="loader-gif" />
    </div>
  )
}

export default Loader
