export const getUnicode = () => {
  // Generate a random 8-character hexadecimal string
  const randomHex = Math.random().toString(16).substring(2, 10)

  // Generate a random UUID segment
  const randomUUIDSegment = Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)

  // Generate the final random string
  const randomString = `${randomHex}-${randomUUIDSegment}-11ed-b6c4-129dece04897`

  return randomString
}

function getCurrentDateTimeFormatted() {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = now.getFullYear()
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  const formattedDateTime = `${day}/${month}/${year}-${hours}:${minutes}:${seconds}`
  return formattedDateTime
}

export const getRequestIdentifier = (
  transactionType: string,
  personId: string,
  warehouseName: string,
  palletId: string | number
) => {
  const currentTime = getCurrentDateTimeFormatted()
  const requestId = `${transactionType}-${personId}-${warehouseName}-${palletId}-${currentTime}`

  return requestId
}

export function sortVendorsAlphabatically(vendors: any) {
  const groupedVendors: any = {}

  vendors.forEach((vendor: any) => {
    const firstLetter = vendor.VENDORNAME.charAt(0).toUpperCase()
    if (!groupedVendors[firstLetter]) {
      groupedVendors[firstLetter] = []
    }
    groupedVendors[firstLetter].push(vendor)
  })

  // Sort the vendor objects within each group based on VENDORNAME
  for (const letter in groupedVendors) {
    groupedVendors[letter].sort((a: any, b: any) =>
      a.VENDORNAME.localeCompare(b.VENDORNAME)
    )
  }

  return groupedVendors
}

export const alphabets = Array.from({ length: 26 }, (_, index) =>
  String.fromCharCode(65 + index)
)

export function NumberSort(arr: any) {
  const len = arr.length
  let swapped

  do {
    swapped = false
    for (let i = 0; i < len - 1; i++) {
      if (arr[i] > arr[i + 1]) {
        // Swap elements
        const temp = arr[i]
        arr[i] = arr[i + 1]
        arr[i + 1] = temp
        swapped = true
      }
    }
  } while (swapped)

  return arr
}

export function checkIsInputInteger(inputString: any) {
  // Use a regular expression to check if the string contains only digits
  return /^\d+$/.test(inputString)
}

export function validateString(inputString: string) {
  // Check if the string contains only alphanumeric characters and has a length <= 19
  return /^[0-9]{1,19}$/.test(inputString)
}
