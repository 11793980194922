import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'
import utilitySlice from './utilitySlice'
import personDataSlice from './personData'
import persistedDataSlice from './persistedDataSlice'
import dashboardDataSlice from './dashboard'
import pricingSlice from './pricing'

const persistConfig = {
  key: 'root',
  storage: sessionStorage, // Use localStorage for all slices
  blacklist: ['utility'], // Exclude 'utility' slice from persistence
}

// Update the persistConfig for persistedDataSlice to use localStorage
const persistedDataPersistConfig = {
  key: 'persistedData',
  storage: localStorage,
}

const persistedReducer = combineReducers({
  utility: utilitySlice,
  personData: personDataSlice,
  persistedData: persistReducer(persistedDataPersistConfig, persistedDataSlice),
  dashboard: dashboardDataSlice,
  pricing: pricingSlice,
})

const persistedRootReducer = persistReducer(persistConfig, persistedReducer)

export default persistedRootReducer
