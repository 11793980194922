import { createSlice } from '@reduxjs/toolkit'
import type {
  stateInterface,
  vendorDataInterface,
  vendorInterface,
  slideMenuInterface,
  printerInterface,
  lastSelectedPrinterInterface,
} from '../../types/dashboard'

const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState: {
    vendorList: [],
    vendorListOriginal: [],
    selectedSlideMenu: 'dashboard',
    lastSelectedPrinter: '',
    isPrinterAvailable: false,
    teamNumbers: [],
    recentNotifications: [],
    selectedVendor: {
      ID: 0,
      VENDORNAME: '',
    },
    isFullScreen: false,
    categoryList: [],
    selectedMainCategory: '',
    selectedSubCategory: '',
  },
  reducers: {
    setVendorList: (state: stateInterface, action: vendorDataInterface) => {
      state.vendorList = action.payload.sorted
      state.vendorListOriginal = action.payload.original
    },
    resetVendorList: (state: stateInterface) => {
      state.vendorList = []
      state.vendorListOriginal = []
    },
    setSelectedVendor: (state: stateInterface, action: vendorInterface) => {
      state.selectedVendor = action.payload
    },
    removeSelectedVendor: (state: stateInterface) => {
      state.selectedVendor = {
        ID: 0,
        VENDORNAME: '',
      }
    },
    setSelectedSideMenu: (
      state: stateInterface,
      action: slideMenuInterface
    ) => {
      state.selectedSlideMenu = action.payload.selectedSlideMenu
    },
    setPrinterStatus: (state: stateInterface, action: printerInterface) => {
      state.isPrinterAvailable = action.payload.isPrinterAvailable
    },
    setLastSelectedPrinter: (
      state: stateInterface,
      action: lastSelectedPrinterInterface
    ) => {
      state.lastSelectedPrinter = action.payload.lastSelectedPrinter
    },
    removeVendorList: (state: stateInterface) => {
      state.vendorList = []
    },
    setTeamNumbers: (state: stateInterface, action: any) => {
      state.teamNumbers = action.payload
    },
    updateFullScreen: (state: stateInterface) => {
      state.isFullScreen = !state.isFullScreen
    },
    setCategoryList: (state: any, action: any) => {
      state.categoryList = action.payload
    },
    removeCategoryList: (state: any) => {
      state.categoryList = []
    },
    setMainCategory: (state: any, action: any) => {
      state.selectedMainCategory = action.payload
    },
    removeMainCategory: (state: any) => {
      state.selectedMainCategory = ''
    },
    setSubCategory: (state: any, action: any) => {
      state.selectedSubCategory = action.payload
    },
    removeSubCategory: (state: any) => {
      state.selectedSubCategory = ''
    },
    updateRecentNotifications: (state: stateInterface, action: any) => {
      state.recentNotifications.unshift(action.payload)
    },
    removeAllNotifications: (state: stateInterface) => {
      state.recentNotifications = []
    },
    deleteNotification: (state: stateInterface, action: any) => {
      state.recentNotifications = state.recentNotifications.filter(
        (item: any) => item.id !== action.payload
      )
    },
  },
})

export const {
  setVendorList,
  resetVendorList,
  removeVendorList,
  setSelectedVendor,
  removeSelectedVendor,
  setSelectedSideMenu,
  setPrinterStatus,
  setLastSelectedPrinter,
  setTeamNumbers,
  updateFullScreen,
  setCategoryList,
  removeCategoryList,
  setMainCategory,
  removeMainCategory,
  updateRecentNotifications,
  deleteNotification,
  removeAllNotifications,
  setSubCategory,
  removeSubCategory,
} = dashboardDataSlice.actions
export default dashboardDataSlice.reducer
