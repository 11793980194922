import './style.scss'
import Breadcrumb from '@components/Breadcrumb'

import belt from '@img/TemplateACC/belt.svg'
import boxers from '@img/TemplateACC/boxers.svg'
import bra from '@img/TemplateACC/bra.svg'
import gloves from '@img/TemplateACC/gloves.svg'
import hair_accessories from '@img/TemplateACC/hair_accessories.svg'
import hat from '@img/TemplateACC/hat.svg'
import hosiery from '@img/TemplateACC/hosiery.svg'
import onesie from '@img/TemplateACC/onesie.svg'
import purse from '@img/TemplateACC/purse.svg'
import scarf from '@img/TemplateACC/scarf.svg'
import shapewear from '@img/TemplateACC/shapewear.svg'
import socks from '@img/TemplateACC/socks.svg'
import sun_glasses from '@img/TemplateACC/sun_glasses.svg'
import tie from '@img/TemplateACC/tie.svg'
import under_shirt from '@img/TemplateACC/under_shirt.svg'

import Card from '@root/src/components/Card'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import { useState } from 'react'
import Loader from '@components/Loader'
import { useDispatch, useSelector } from 'react-redux'
import CustomPopup from '@root/src/components/CustomPopup'
import { setNotification } from '@store/Slices/utilitySlice'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'
export default function TemplateAcc({
  TEMPLATE_ACC,
  selectedVendor,
  selectedTemplateAccCat,
  setSelectedTemplateAccCat,
  showPopup,
  setShowPopup,
  addDamageItem,
}: any) {
  const { getBarcodeData } = useClothingEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo } = useSelector((store: any) => store.pricing)
  const { barcodeInfo } = useSelector((store: any) => store.pricing)
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  // const dispatch = useDispatch()

  const svgImgs = [
    bra,
    purse,
    hat,
    tie,
    boxers,
    under_shirt,
    sun_glasses,
    hair_accessories,
    socks,
    gloves,
    scarf,
    hosiery,
    belt,
    onesie,
    shapewear,
  ]

  const loader = useSelector((store: any) => store.utility.loader)
  const [qnt, setQnt] = useState<string>('1')
  const dispatch = useDispatch()
  function getBarcodeDetailsAndPrint(selectedItem: any) {
    const description = selectedItem.description.join('/')

    getBarcodeData(
      103,
      parseInt(qnt),
      TEMPLATE_ACC?.header?.sub_cat[0]?.stock_2_cat_id,
      '',
      '' + personData?.personName + '' + personData?.lastName,
      personData?.personId,
      palletInfo?.RawPalletId,
      'template_acc',
      '' + selectedItem?.cat_id,
      'Accessories',
      description
    )
  }

  return (
    <div className="clothing-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: 'Please enter quantity between 1-30',
                  variant: 'warning',
                })
              )
              return
            }

            setShowPopup(false)
            getBarcodeDetailsAndPrint(selectedTemplateAccCat)
            setQnt('1')
          }}
          cancelButtonAction={(e: any) => {
            setShowPopup(false)
          }}
        />
      )}

      <div className="left-container">
        <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Template ACC']} />
        {TEMPLATE_ACC?.category?.length ? (
          <div className="clothing-cards">
            {/* FIRST SECTION */}
            <div className="adult-section">
              {TEMPLATE_ACC.category
                .slice(0, 5)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={TEMPLATE_ACC?.translation}
                    imgURI={svgImgs[index]}
                    imgAlt={item.cat_id}
                    imgWidth={70}
                    onClick={() => {
                      setShowPopup(true)
                      setSelectedTemplateAccCat(item)
                    }}
                    selectedCard={item === selectedTemplateAccCat}
                  />
                ))}
            </div>

            {/* SECOUND SECTION */}
            <div className="child-section">
              {TEMPLATE_ACC?.category
                .slice(5, 10)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={TEMPLATE_ACC?.translation}
                    imgURI={svgImgs[index + 5]}
                    imgWidth={70}
                    onClick={() => {
                      setShowPopup(true)
                      setSelectedTemplateAccCat(item)
                    }}
                    selectedCard={item === selectedTemplateAccCat}
                  />
                ))}
            </div>

            {/* THIRD SECTION */}
            <div className="adult-swimwear-section">
              {TEMPLATE_ACC.category
                .slice(10)
                .map((item: any, index: number) => (
                  <Card
                    key={index}
                    info={item.description}
                    translations={TEMPLATE_ACC?.translation}
                    imgURI={svgImgs[index + 10]}
                    imgWidth={70}
                    onClick={() => {
                      setShowPopup(true)
                      setSelectedTemplateAccCat(item)
                    }}
                    selectedCard={item === selectedTemplateAccCat}
                  />
                ))}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>

      {TEMPLATE_ACC?.category?.length && <hr className="clothing-hr" />}

      {TEMPLATE_ACC?.category?.length ? (
        <InfoSidebarPricing
          stockName={TEMPLATE_ACC?.header?.main_cat?.stock_name}
          subCat={TEMPLATE_ACC?.header?.sub_cat[0]?.sub_cat_name}
          mainCat={selectedSubCategory}
          stock2Cat={TEMPLATE_ACC?.header?.sub_cat[0]?.stock_2_cat_id}
          Stock={TEMPLATE_ACC?.header?.main_cat?.stock_number}
          priceRange={selectedTemplateAccCat?.price_range}
          price={barcodeInfo?.price || ''}
          hidePriceRange={true}
          addDamageItem={addDamageItem}
        />
      ) : (
        ''
      )}
    </div>
  )
}
