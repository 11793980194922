import { trackEvent } from '@components/AppInsights/AppInsightsConfig'
import axios, { type AxiosInstance } from 'axios'

export const publicAxios: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_OAUTH_ENDPOINT,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
    // 'content-type': 'application/json',
  },
})

// Add a response interceptor
publicAxios.interceptors.response.use(
  (response) => {
    console.log('response.data', response.data)

    if (!response.request.fromCache) {
      const {
        config: { url, method, data },
        status,
      } = response
      trackEvent({
        url,
        httpMethod: method?.toUpperCase(),
        httpStatusCode: status,
        payload: data || null,
        response: JSON.stringify(response.data),
        success: true,
      })
    }
    if (response.data) return response.data
    return Promise.reject(response)
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

export default publicAxios
