import { createSlice } from '@reduxjs/toolkit'
import type {
  loaderInterface,
  loadingstateInterface,
  notificationInterface,
  notificationStateInterface,
} from '../../types/utility'

const utilitySlice = createSlice({
  name: 'utility',
  initialState: {
    loader: false,
    isDarkTheme: false,
    notification: '',
    variant: '',
    translation: '',
  },
  reducers: {
    setLoader: (state: loadingstateInterface, action: loaderInterface) => {
      state.loader = action.payload
    },
    setDarkTheme: (state: loadingstateInterface, action: loaderInterface) => {
      state.isDarkTheme = action.payload
    },
    setNotification: (
      state: notificationStateInterface,
      action: notificationInterface
    ) => {
      state.notification = action.payload.notification
      state.variant = action.payload.variant
      state.translation = action.payload.translation
    },
  },
})

export const { setLoader, setNotification, setDarkTheme } = utilitySlice.actions
export default utilitySlice.reducer
