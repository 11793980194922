import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import { useSelector, useDispatch } from 'react-redux'
import Loader from '@components/Loader'
import CustomPopup from '@root/src/components/CustomPopup'
import { useState } from 'react'
import { setNotification } from '@store/Slices/utilitySlice'
import NoUPCPopup from '@root/src/components/CustomPopup/noUPC'
import useUPCScanEnhancer from '@tabs/UPCScan/useUPCScanEnhancer'
import { removeBarcodeInfo } from '@store/Slices/pricing'
import useClothingEnhancer from '@tabs/Clothing/useClothingEnhancer'

export default function Misc({
  MISC,
  selectedVendor,
  selectedMiscCat,
  setSelectedMiscCat,
  showPopup,
  setShowPopup,
  showNoUPCPopup,
  setShowNoUPCPopup,
  t,
  addDamageItem,
  otherAmoutPrice,
  setOtherAmoutPrice,
  otherAmoutSelected,
  setotherAmoutSelected,
  option,
}: any) {
  const loader = useSelector((store: any) => store.utility.loader)
  const { getBarcodeData } = useClothingEnhancer()
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo } = useSelector((store: any) => store.pricing)
  const { barcodeInfo } = useSelector((store: any) => store.pricing)
  const [qnt, setQnt] = useState<string>('1')
  const dispatch = useDispatch()
  const [newPrice, setPrice] = useState<string>('')
  const { getItemDetails } = useUPCScanEnhancer({ option })
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  function getBarcodeDetailsAndPrint(selectedItem: any) {
    console.log(selectedMiscCat)
    getBarcodeData(
      111,
      parseInt(qnt),
      MISC?.header?.sub_cat[0]?.stock_2_cat_id,
      '',
      '' + personData?.personName + '' + personData?.lastName,
      personData?.personId,
      palletInfo?.RawPalletId,
      'misc',
      '' + selectedItem?.cat_id,
      'Misc',
      selectedItem.description.join(' ')
    )
  }

  return (
    <div className="misc-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          maxLimitText={'Max limit 50'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            console.log(e)
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 50 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-50'),
                  variant: 'warning',
                })
              )
              return
            }
            setShowPopup(false)
            getBarcodeDetailsAndPrint(selectedMiscCat)
            setQnt('1')

            console.log(e)
          }}
          cancelButtonAction={(e: any) => {
            console.log(e)
            setShowPopup(false)
          }}
        />
      )}
      {showNoUPCPopup && (
        <NoUPCPopup
          showDescField={false}
          maxLimitText={'Max limit 50'}
          headline={'Other amount'}
          quantity={parseInt(qnt)}
          priceValue={newPrice}
          editPrice={false}
          onPriceChange={(e: any) => {
            console.log(e)
            setPrice(e)

            // Ensure input only contains digits and at most one decimal point
            const sanitizedPrice = e.replace(/[^0-9.]/g, '')
            const decimalParts = sanitizedPrice.split('.')
            if (decimalParts.length > 2) {
              setPrice(newPrice)
              return
            }

            // Limit decimal places to two
            if (decimalParts[1] && decimalParts[1].length > 2) {
              setPrice(newPrice)
              return
            }

            // Limit decimal places to 4
            if (decimalParts[0] && decimalParts[0].length > 4) {
              setPrice(newPrice)
              return
            }

            setPrice(sanitizedPrice)
          }}
          onChangeQty={(e: any) => {
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }

            const decimalParts = e.split('.')
            if (e === '') {
              return
            }
            console.log('DECIMAL VALIE', decimalParts)
            // Limit decimal places to 4
            if (decimalParts[0] && decimalParts[0].length > 2) {
              setQnt(qnt)
              return
            }
            if (decimalParts[0] && decimalParts[0].length > 2) {
              setQnt(qnt)
              return
            }

            setQnt(e)
          }}
          standardPriceButtonAction={(e: any) => {
            console.log(e)
          }}
          okButtonAction={(e: any) => {
            if (
              parseFloat(newPrice) > 9999.99 ||
              parseFloat(newPrice) < 0.01 ||
              newPrice === '.' ||
              newPrice === ''
            ) {
              dispatch(
                setNotification({
                  notification: t('Please enter price between 0.01 - 9999.99'),
                  variant: 'warning',
                })
              )
              return
            }

            if (parseInt(qnt) > 50 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-50'),
                  variant: 'warning',
                })
              )
              return
            }
            if (newPrice === '') {
              dispatch(
                setNotification({
                  notification: t('Please enter the price'),
                  variant: 'warning',
                })
              )
              return
            }

            setShowNoUPCPopup(false)
            setOtherAmoutPrice(newPrice)
            getItemDetails(0, newPrice, '', parseInt(qnt), true, true)

            setPrice('')
            setQnt('1')
          }}
          cancelButtonAction={(e: any) => {
            setShowNoUPCPopup(false)
          }}
        />
      )}

      <div>
        <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Misc']} />
        <div className="misc-content">
          <div className="misc-sizes">
            {MISC?.category?.map((item: any) => (
              <div
                className={
                  item === selectedMiscCat
                    ? 'base-misc-box size-container-selected'
                    : 'base-misc-box'
                }
                key={item?.description[0]}
                onClick={() => {
                  setSelectedMiscCat(item)
                  setOtherAmoutPrice('')
                  setShowPopup(true)
                  setotherAmoutSelected(false)
                }}
              >
                {item?.description[0]}
              </div>
            ))}
            {MISC?.category?.length && (
              <div
                className={
                  !otherAmoutSelected
                    ? 'base-misc-box'
                    : 'base-misc-box size-container-selected'
                }
                key={'Other amount'}
                onClick={() => {
                  setOtherAmoutPrice('')
                  dispatch(removeBarcodeInfo())
                  setotherAmoutSelected(true)
                  setSelectedMiscCat({})
                  setShowNoUPCPopup(true)
                }}
              >
                Other amount
              </div>
            )}
          </div>
        </div>
      </div>

      {MISC?.category?.length && <hr className="clothing-hr" />}

      {MISC?.category?.length ? (
        <InfoSidebarPricing
          stockName={MISC?.header?.main_cat?.stock_name}
          subCat={MISC?.header?.sub_cat[0]?.sub_cat_name}
          mainCat={selectedSubCategory}
          stock2Cat={MISC?.header?.sub_cat[0]?.stock_2_cat_id}
          Stock={MISC?.header?.main_cat?.stock_number}
          hidePriceRange={true}
          price={barcodeInfo?.price || otherAmoutPrice}
          addDamageItem={addDamageItem}
        />
      ) : (
        ''
      )}
    </div>
  )
}
