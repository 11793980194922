import { trackEvent } from '@components/AppInsights/AppInsightsConfig'
import { getUnicode } from '@helpers/index'
import { setNotification } from '@store/Slices/utilitySlice'
import store from '@store/index'
import axios, { type AxiosInstance } from 'axios'

export const axiosClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_UPC_URL,
  timeout: 20000,
  headers: {
    source_system: 'web-application',
    source_channel: 'HTTPS',
    target_system: 'DMS',
    target_channel: 'DB',
    request_identifier: getUnicode(),
    correlation_id: getUnicode(),
    scope: 'READ',
  },
})
// Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent

    const basicAuth = sessionStorage.getItem('Authorization')
    config.headers.request_identifier = getUnicode()
    config.headers.correlation_id = getUnicode()

    if (basicAuth) {
      config.headers.Authorization = `Bearer ${basicAuth}`
    }
    return config
  },
  async (error) => {
    console.log(error, 'API error')
    return await Promise.reject(error)
  }
)

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response: any) => {
    console.log('response.data', response.data)

    if (!response.request.fromCache) {
      const {
        config: { url, method, data },
        status,
      } = response
      trackEvent({
        url,
        httpMethod: method?.toUpperCase(),
        httpStatusCode: status,
        payload: data || null,
        response: JSON.stringify(response.data),
        success: true,
      })
    }
    if (response.data) return response.data
    return Promise.reject(response)
  },
  async (error) => {
    console.log(error, 'API error', error)
    return await Promise.reject(error)
  }
)

async function upcGetRequest(URL: string, additionalHeaders?: object) {
  try {
    const upcTimeout: any = process.env.REACT_APP_UPC_TIMEOUT
    axiosClient.defaults.timeout = parseInt(upcTimeout) || 5000

    const response = await axiosClient
      .get(URL, additionalHeaders)
      .then((response) => {
        // Handle the successful response here
        return response
      })
      .catch((error) => {
        if (error.code === 'ECONNABORTED' && URL.includes('/pricing/details')) {
          // Handle the timeout error here
          store.dispatch(
            setNotification({
              notification:
                'Please rescan again after scanning few more items.',
              variant: 'error',
            })
          )
        } else if (
          error.code === 'ERR_BAD_RESPONSE' &&
          URL.includes('/pricing/details')
        ) {
          // Handle other errors
          store.dispatch(
            setNotification({
              notification:
                'Item not in database, see your supervisor for the price',
              variant: 'warning',
            })
          )
          console.error(error)
        } else {
          store.dispatch(
            setNotification({
              notification: 'Timeout reached, please retry after sometime',
              variant: 'error',
            })
          )
        }
      })
      .catch((customError) => {
        // Handle the custom error with your custom message
        console.error(customError.message)
      })
    console.log(URL, '==========>', response)
    return response
  } catch (error) {
    return error
  }
}

export { upcGetRequest }

export default axiosClient
