import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setNotification } from '@store/Slices/utilitySlice'
import {
  setPrinterStatus,
  setLastSelectedPrinter,
} from '@store/Slices/dashboard'
import {
  setPrintZplData,
  setSelectedPrinter,
} from '@store/Slices/persistedDataSlice'
import { useTranslation } from 'react-i18next'

export const PrintZPL = () => {
  const { printZplString, printValues, selectedPrinter, showNotification } =
    useSelector((store: any) => store.persistedData)
  const { lastSelectedPrinter } = useSelector((store: any) => store.dashboard)
  const [printerList, setPrinterList] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    printZplString && sendZPLtoPrinter()
    getPrintersFromBrowserPrint()
  }, [printZplString])

  useEffect(() => {
    getPrintersFromBrowserPrint()
    getSelectedPrinterFromList(lastSelectedPrinter)
  }, [])

  function getSelectedPrinterFromList(value: string) {
    const selectedPrin = printerList?.filter((item: any) => item.uid === value)

    if (selectedPrin && selectedPrin?.length !== 0) {
      dispatch(
        setSelectedPrinter({
          selectedPrinter: selectedPrin[0],
        })
      )
      dispatch(setPrinterStatus({ isPrinterAvailable: true }))
    }
  }
  const getPrintersFromBrowserPrint = () => {
    const browserPrint = (window as any).BrowserPrint

    browserPrint.getLocalDevices(
      function (deviceList: any) {
        setPrinterList(deviceList.printer)
        if (lastSelectedPrinter === '') {
          const selectedPrin = deviceList.printer?.filter(
            (item: any) => item.connection === 'usb'
          )

          if (selectedPrin && selectedPrin?.length !== 0) {
            dispatch(
              setSelectedPrinter({
                selectedPrinter: selectedPrin[0],
              })
            )
            dispatch(setPrinterStatus({ isPrinterAvailable: true }))
          }
        }
      },
      (err: any) => {
        console.log(err)
      }
    )
  }
  const sendZPLtoPrinter = () => {
    dispatch(
      setPrintZplData({
        printZplString: false,
        printValues: '',
        showNotification: true,
      })
    )
    if (
      selectedPrinter === undefined ||
      selectedPrinter === null ||
      selectedPrinter === ''
    ) {
      getPrintersFromBrowserPrint()
      getSelectedPrinterFromList(lastSelectedPrinter)
      dispatch(
        setNotification({
          notification: t('Printer is not connected'),
          variant: 'warning',
        })
      )

      dispatch(
        setPrinterStatus({
          isPrinterAvailable: false,
        })
      )
    } else {
      try {
        selectedPrinter.send(
          printValues,
          (success: any) => {
            showNotification &&
              dispatch(
                setNotification({
                  notification: t('Label is printed'),
                  variant: 'success',
                })
              )
          },
          (error: any) => {
            console.log('printServiceMessage', 'error' + error)
            dispatch(
              setLastSelectedPrinter({
                lastSelectedPrinter: '',
              })
            )

            dispatch(
              setSelectedPrinter({
                selectedPrinter: null,
              })
            )
            getPrintersFromBrowserPrint()
            dispatch(
              setPrinterStatus({
                isPrinterAvailable: false,
              })
            )

            dispatch(
              setNotification({
                notification: t('Please retry or reconnect the printer'),
                variant: 'error',
              })
            )
          }
        )
      } catch (e) {
        console.log('Printer exception', e)
        dispatch(
          setPrinterStatus({
            isPrinterAvailable: false,
          })
        )
        getPrintersFromBrowserPrint()
        getSelectedPrinterFromList(lastSelectedPrinter)

        dispatch(
          setNotification({
            notification: t('Please retry or reconnect the printer'),
            variant: 'error',
          })
        )
      }
    }
  }
  return <></>
}
