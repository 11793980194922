import CustomInput from '@components/Input'
import './style.scss'
import CustomButton from '@components/CustomButton'
import { InputGroup } from 'rsuite'
import Close from '@img/close.svg'

const CustomPopup = ({
  onChange,
  headline,
  cancelButtonAction,
  okButtonAction,
  maxLimitText,
  qnt,
}: any) => {
  return (
    <div className="loader-container">
      <div className="popup-layer">
        <div className="popup-header">
          <p className="popup-headline">{headline}</p>
          <InputGroup.Addon
            style={{ backgroundColor: 'transparent', border: '0px' }}
          >
            <img
              src={Close}
              className="input_icon"
              onClick={cancelButtonAction}
            />
          </InputGroup.Addon>
        </div>
        <label>Enter Quantity</label>
        <CustomInput
          onChange={onChange}
          type="number"
          value={qnt}
          onKeyDown={(event: any) => {
            if (event.keyCode === 13 || event.key === 'Enter') {
              okButtonAction()
            }
          }}
        />
        <p className="info-para">{maxLimitText ?? 'Max limit 30'}</p>
        <div className="popup-btns">
          <CustomButton
            buttonText="Ok"
            width="80px"
            activeColor={'#D5D1D1'}
            activeTextColor="#24272C"
            onClick={okButtonAction}
            isActive
          />
        </div>
      </div>
    </div>
  )
}

export default CustomPopup
