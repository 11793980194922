/* eslint-disable @typescript-eslint/no-misused-promises */
import './style.scss'
import ccmLogo from '@img/CCM-logo.svg'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedSideMenu } from '@store/Slices/dashboard'
import useLogoutEnhancer from '@helpers/useLogutEnhancer'

export default function Sidebar({ t }: any) {
  const items = ['dashboard', 'printer_setting'] // list items
  const dispatch = useDispatch()
  const { logoutUser } = useLogoutEnhancer()
  const { selectedSlideMenu } = useSelector((store: any) => store.dashboard)

  return (
    <div className="wrapper">
      <div className="side_nav_content">
        <div className="app_icon">
          <img src={ccmLogo} className="ccmlogo" />
        </div>
        <div className="nav_option">
          <div className="list-view">
            {items.map((item, index) => (
              <div
                className={
                  'list-item-' +
                  (selectedSlideMenu === item ? 'selected' : 'unselected')
                }
                key={index}
                onClick={() => {
                  dispatch(
                    setSelectedSideMenu({
                      selectedSlideMenu: item,
                    })
                  )
                }}
              >
                {t(item)}
              </div>
            ))}
          </div>

          <div className="general-info-sidebar">
            {process.env.REACT_APP_ENV !== 'prod' && (
              <div className="info info1">
                Env : {process.env.REACT_APP_ENV}
              </div>
            )}
            <div className="info info1">{t('station_id')} : FB-SWPL-28</div>
            <div className="info info2">
              {t('version')} : {process.env.REACT_APP_VERSION}
            </div>
          </div>

          <div className="footer" onClick={logoutUser}>
            {t('logout')}
          </div>
        </div>
      </div>
    </div>
  )
}
