import './style.scss'
import editIcon from '@img/edit-icon.svg'

export default function EditCircle() {
  return (
    <div className="edit-circle">
      <img src={editIcon} alt={'editIcon'} />
    </div>
  )
}
