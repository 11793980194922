import './style.scss'
import Breadcrumb from '@components/Breadcrumb'
import Top from '@img/Top.svg'
import Bottom from '@img/Bottom.svg'
import Outwear from '@img/Outwear.svg'
import Formalwear from '@img/Formalwear.svg'
import Infant from '@img/Infant.svg'
import Swimwear from '@img/Swimwear.svg'
import Sets from '@img/Sets.svg'
import Outwear2 from '@img/Outwear2.svg'
import OnePieceSwimwear from '@img/One-Piece-Swimwear.svg'
import Bikini from '@img/Bikini.svg'

import AllSeason from '@img/LetterClothing/allseason.svg'
import Outerwear from '@img/LetterClothing/outerwear.svg'
import LetterSwimwear from '@img/LetterClothing/swimwear.svg'
import Christmas from '@img/LetterClothing/christmas.svg'
import Halloween from '@img/LetterClothing/halloween.svg'
import StPatrik from '@img/LetterClothing/stpatrik.svg'
import FormalWear from '@img/LetterClothing/formalwear.svg'
import ChildCLothing from '@img/LetterClothing/childclothing.svg'
import ChildOuterwear from '@img/LetterClothing/childouterwear.svg'

import Card from '@root/src/components/Card'
import InfoSidebarPricing from '@root/src/components/InfoSideBarPricing'
import { useState } from 'react'
import useClothingEnhancer from './useClothingEnhancer'
import Loader from '@components/Loader'
import { useSelector, useDispatch } from 'react-redux'
import CustomPopup from '@root/src/components/CustomPopup'
import {
  removeBarcodeInfo,
  setCateory,
  setSelectedLetterClothingCategory,
} from '@store/Slices/pricing'
import { setNotification } from '@store/Slices/utilitySlice'
import useUPCScanEnhancer from '@tabs/UPCScan/useUPCScanEnhancer'

export default function Clothing({
  CLOTHING,
  selectedVendor,
  selectedClothingCat,
  setSelectedClothingCat,
  showPopup,
  setShowPopup,
  barcodeDesc,
  setBarcodeDesc,
  barcodeTitle,
  setBarcodeTitle,
  t,
  addDamageItem,
  isLetterClothing,
  option,
  selectedLetterClothingCat,
  setSelectedLetterClothingCat,
}: any) {
  const { getBarcodeData } = useClothingEnhancer()
  const { getItemDetails } = useUPCScanEnhancer({ option })
  const { personData } = useSelector((store: any) => store.personData)
  const { palletInfo, selectedCategory } = useSelector(
    (store: any) => store.pricing
  )
  const dispatch = useDispatch()
  const { selectedSubCategory } = useSelector((store: any) => store.dashboard)

  const svgImgs = [
    Top,
    Bottom,
    Outwear,
    Formalwear,
    Infant,
    Sets,
    Outwear2,
    Swimwear,
    OnePieceSwimwear,
    Bikini,
  ]

  const letterClothingImgs = [
    { title: 'CHILD OUTERWEAR', img: ChildOuterwear },
    { title: 'ALL SEASON', img: AllSeason },
    { title: 'OUTERWEAR', img: Outerwear },
    { title: 'SWIMWEAR', img: LetterSwimwear },
    { title: 'CHRISTMAS', img: Christmas },
    { title: 'HALLOWEEN', img: Halloween },
    { title: 'ST.PATRICK', img: StPatrik },
    { title: 'FORMALWEAR', img: FormalWear },
    { title: 'CHILD', img: ChildCLothing },
  ]

  const loader = useSelector((store: any) => store.utility.loader)
  const [qnt, setQnt] = useState<string>('1')

  const findItems = (category: any, type: string) => {
    const searchWord = 'PIECE'
    const foundItems = []

    // Loop through the "category" array
    for (const item of category) {
      if (item?.description && item?.description?.A) {
        // Loop through the "A" array within the "description"
        for (const str of item?.description?.A) {
          if (
            type === 'adult'
              ? !str.includes(searchWord)
              : str.includes(searchWord)
          ) {
            foundItems.push(item)
            break // No need to continue searching for "PIECE" in this object
          }
        }
      }
    }
    return foundItems
  }
  function getBarcodeTitleSWIMWARE(selectedItem: any) {
    const descriptionA = selectedItem.description.A.join(' ')
    setBarcodeDesc(selectedItem.description.A.join('/'))
    let objectValue = 'Clothing'
    CLOTHING?.header?.sub_cat.forEach((item: any) => {
      if (
        descriptionA.includes('SWIMWEAR') &&
        item.sub_cat_name.includes('SWIMWEAR')
      ) {
        objectValue = 'Swimwear'
      } else if (
        descriptionA.includes('OUTERWEAR') &&
        item.sub_cat_name.includes('OUTERWEAR')
      ) {
        objectValue = 'Outerwear'
      } else if (
        descriptionA.includes('FORMALWEAR') &&
        item.sub_cat_name.includes('FORMALWEAR')
      ) {
        objectValue = 'Formalwear'
      } else {
        objectValue = 'Clothing'
      }
    })
    setBarcodeTitle(objectValue)
  }
  function getBarcodeTitleChild(selectedItem: any) {
    const descriptionA = selectedItem.description.C.join(' ')
    setBarcodeDesc(selectedItem.description.C.join('/'))
    let objectValue = 'Child Clothing'
    CLOTHING?.header?.sub_cat.forEach((item: any) => {
      if (
        descriptionA.includes('SWIMWEAR') &&
        item.sub_cat_name.includes('SWIMWEAR')
      ) {
        objectValue = 'Child Swimwear'
      } else if (
        descriptionA.includes('OUTERWEAR') &&
        item.sub_cat_name.includes('OUTERWEAR')
      ) {
        objectValue = 'Child Outerwear'
      } else if (
        descriptionA.includes('FORMALWEAR') &&
        item.sub_cat_name.includes('FORMALWEAR')
      ) {
        objectValue = 'Formalwear'
      } else if (
        descriptionA.includes('TOPS') ||
        (descriptionA.includes('BOTTOMS') &&
          item.sub_cat_name.includes('CLOTHING CHILD'))
      ) {
        objectValue = 'Child Clothing'
      } else {
        objectValue = 'Child Clothing'
      }
    })
    setBarcodeTitle(objectValue)
  }
  function getLetterClothingDesc(desc: string) {
    const value = desc?.split(')')
    const newDesc = [...value].pop()
    return newDesc
  }

  function getStockID() {
    if (isLetterClothing) {
      return '' + selectedLetterClothingCat?.stock_2_cat_id === undefined
        ? ''
        : selectedLetterClothingCat?.stock_2_cat_id
    } else {
      return CLOTHING?.header?.sub_cat[0]?.stock_2_cat_id
    }
  }

  return (
    <div className="clothing-wrapper">
      {loader && <Loader />}
      {showPopup && (
        <CustomPopup
          headline={'Quantity'}
          qnt={parseInt(qnt)}
          onChange={(e: any) => {
            console.log(e)
            if (!e) {
              setQnt('0')
            } else {
              setQnt(e)
            }
          }}
          okButtonAction={(e: any) => {
            if (parseInt(qnt) > 30 || parseInt(qnt) < 1) {
              dispatch(
                setNotification({
                  notification: t('Please enter quantity between 1-30'),
                  variant: 'warning',
                })
              )
              return
            }
            dispatch(removeBarcodeInfo())

            getBarcodeData(
              96,
              parseInt(qnt),
              CLOTHING?.header?.sub_cat[0]?.stock_2_cat_id,
              '',
              '' + personData?.personName + '' + personData?.lastName,
              personData?.personId,
              palletInfo?.RawPalletId,
              'clothing',
              '' + selectedClothingCat?.cat_id,
              barcodeTitle,
              barcodeDesc
            )

            dispatch(setCateory(selectedClothingCat))

            setShowPopup(false)
            setQnt('1')
          }}
          cancelButtonAction={(e: any) => {
            setShowPopup(false)
          }}
        />
      )}

      {isLetterClothing ? (
        <div className="letter-clothing-wrapper">
          <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Clothing']} />
          <div className="letter-clothing-container">
            {CLOTHING?.category?.map((item: any, index: number) => (
              <Card
                key={index}
                info={[getLetterClothingDesc(item.description)]}
                translations={''}
                imgURI={
                  letterClothingImgs.find((lcItem) =>
                    item.description.includes(lcItem.title)
                  )?.img ||
                  letterClothingImgs.find((lcItem) =>
                    lcItem.title.includes('ALL SEASON')
                  )?.img
                }
                imgAlt={letterClothingImgs.find((lcItem) =>
                  item.description.includes(lcItem.title)
                )}
                imgWidth={'60px'}
                onClick={() => {
                  setSelectedLetterClothingCat(item)
                  dispatch(setSelectedLetterClothingCategory(item))
                }}
                style={{ height: '100px' }}
                selectedCard={item === selectedLetterClothingCat}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="left-container">
          <Breadcrumb items={[selectedVendor?.VENDORNAME, 'Clothing']} />
          {CLOTHING?.category?.length ? (
            <div className="clothing-cards">
              {/* ADULT SECTION */}
              <div className="adult-section">
                <p className="cat-title">{t('Adult')}</p>
                {findItems(CLOTHING.category, 'adult').map(
                  (item: any, index: number) => (
                    <Card
                      key={index}
                      info={item.description.A}
                      translations={CLOTHING?.translation}
                      imgURI={svgImgs[index]}
                      imgAlt={item.cat_id}
                      onClick={() => {
                        setShowPopup(true)
                        setSelectedClothingCat(item)
                        getBarcodeTitleSWIMWARE(item)
                      }}
                      selectedCard={item === selectedClothingCat}
                    />
                  )
                )}
              </div>

              {/* CHILD SECTION */}
              <div className="child-section">
                <p className="cat-title">{t('Child')}</p>
                {CLOTHING?.category
                  ?.filter((cat: any) => cat.description.C)
                  .map((item: any, index: number) => (
                    <Card
                      key={index}
                      info={item.description.C}
                      translations={CLOTHING?.translation}
                      imgURI={svgImgs[index + 4]}
                      onClick={() => {
                        setShowPopup(true)

                        setSelectedClothingCat(item)
                        getBarcodeTitleChild(item)
                      }}
                      selectedCard={item === selectedClothingCat}
                    />
                  ))}
              </div>

              {/* ADULT SWIMWEAR SECTION */}
              <div className="adult-swimwear-section">
                <p className="cat-title">{t('Adult Swimwear')}</p>
                {findItems(CLOTHING.category, 'swimwear').map(
                  (item: any, index: number) => (
                    <Card
                      key={index}
                      info={item.description.A}
                      translations={CLOTHING?.translation}
                      imgURI={svgImgs[index + 8]}
                      onClick={() => {
                        setShowPopup(true)

                        setSelectedClothingCat(item)
                        getBarcodeTitleSWIMWARE(item)
                      }}
                      selectedCard={item === selectedClothingCat}
                    />
                  )
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {CLOTHING?.category?.length && <hr className="clothing-hr" />}

      {CLOTHING?.category?.length ? (
        <InfoSidebarPricing
          stockName={CLOTHING?.header?.main_cat?.stock_name}
          subCat={
            isLetterClothing
              ? CLOTHING?.header?.main_cat?.main_cat_name
              : CLOTHING?.header?.sub_cat[0]?.sub_cat_name
          }
          mainCat={selectedSubCategory}
          stock2Cat={getStockID()}
          Stock={CLOTHING?.header?.main_cat?.stock_number}
          priceRange={selectedCategory?.price_range}
          addDamageItem={addDamageItem}
          hidePriceRange={isLetterClothing}
          letterClothing={isLetterClothing}
          getItemDetails={getItemDetails}
          disabledUPCScan={selectedLetterClothingCat?.description === undefined}
          selectedLetterClothingCat={selectedLetterClothingCat}
        />
      ) : (
        ''
      )}
    </div>
  )
}
