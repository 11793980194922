import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

const { REACT_APP_INSTRUMENTATION_KEY } = process.env

const reactPlugin: any = new ReactPlugin()
const appInsights: any = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    // ignoreHeaders: ['Authorization'],
  },
})
appInsights.loadAppInsights()
appInsights.trackPageView()

const trackEvent = (properties: any) => {
  if (REACT_APP_INSTRUMENTATION_KEY !== 'none') {
    appInsights.trackEvent({ name: 'Track API', properties })
  }
}

export { reactPlugin, trackEvent }
